/* WEATHER */
.sunrise { float: right; font-size: .8em; font-weight: normal; }
.weather .div, .weather .div2, .weather .div3 { float: left; width: 15%; text-align: center; }
.weather .div2 { width: 20%; }
.weather .div3 { width: 25%; }
.row1 { padding-top: 12px; }
.weather .div4 { float: left; width: 200px; font-size: .9em; text-align: left; }
.weather .line { clear: both; padding-top: 5px; border-top: 1px solid #ccc; }
.weather .line:hover { background: #fcefd1; }
img.w-icon { margin-top: -4px; height: 64px; }
.current { height: 65px; }
.ctr { text-align: center; }
table { width: 100%; }
.weather .day { font-size: 1em; text-transform: uppercase;  }
.weather .day strong { font-size: 1.1em; }
.temp { font-size: 1.8em; padding-top: 8px; display: inline-block; }
.lpad { margin-left: 42px; }
.trails-app h3 .fa { display: inline-block; width: 42px; }
.temp-deg { font-size: 1.5em; font-weight: bold; color: #9F481B; }
.cold { color: #5294a4; font-size: .9em; }

/* WATER LEVELS & TEMP */
.glass { height: 112px; width: 112px; text-align: baseline; border-radius: 5px; margin: 20px 0 0 0px;
}
.fullness { width: 112px; background-color: aqua; position: absolute; background-image: url(/sites/default/files/underwater.jpg); background-position: center bottom; z-index: 1; bottom: 0px; border-radius: 5px; border-top-left-radius: 0px; border-top: 1px solid #fff; }
.fullness-line { width: 112px; position: absolute; z-index: 2; bottom: 0px; border-top: 1px solid #fff; margin-left: -5px; margin-right: -5px; }
.full { height: 112px; width: 112px; background-image: url(/sites/default/files/waterfull.png); position: absolute; z-index: 2;  }
.trails-app .water .fa { width: auto !important; } 
.therm {  position: relative; height: 114px; width: 17px; text-align: baseline; float: left; margin-top: 20px; }
.temp-full { width: 100%; height: 114px;  position: absolute; background-image: url(https://larimer.org/sites/default/files/temp-grad.png); z-index: 1; bottom: 0px;  }
.temp-empty { width: 100%; position: absolute; background-image: url(https://larimer.org/sites/default/files/temp-grad-empty.png); background-position: center top; z-index: 2; top: 0px; border-bottom: 1px solid #fff; }
.hdr { padding-bottom: 6px; color: #eeeeaa; }

.temp-deg { font-size: 1.6em; font-weight: bold; }
.temp { float: left; width: 130px; margin-left: 15px; font-size: 1.3em; }
.water { font-size: 1.3em; text-align: right; }
.water-h2 { margin-right: 132px; }
.water-temp { text-align: right; font-size: 1.3em; height: 100px; }