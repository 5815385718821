// Mixins
// -----------------------------------------------------------------------------

/* set background colors */

.bg-brand-green {background-color: $brand-green !important}
.bg-brand-lt-green {background-color: $brand-lt-green !important}
.bg-brand-tan {background-color: $brand-tan !important}
.bg-brand-lt-tan {background-color: $brand-lt-tan !important}
.bg-brand-ltr-tan {background-color: $brand-ltr-tan !important}
.bg-brand-blue {background-color: $brand-blue !important}
.bg-brand-lt-blue {background-color: $brand-lt-blue !important}
.bg-brand-ltr-blue {background-color: $brand-ltr-blue !important}
.bg-brand-sienna {background-color: $brand-sienna !important}
.bg-brand-lt-sienna {background-color: $brand-lt-sienna !important}
.bg-brand-brown {background-color: $brand-brown !important}
.bg-brand-gray {background-color: $brand-gray !important}
.bg-brand-lt-gray {background-color: $brand-lt-gray !important}

/*  fix for invisible scroll */
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 13px !important;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(51,51,51,.5) !important;
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

//updates UserEcho
@media only screen and (max-width: 767px) {
  .ue-tab-container {
    display:none !important;
  }
}