// Styles for Menus and Navs
// -----------------------------------------------------------------------------


/* nav-quick */
body.front .iwant-h1.blue, body .main-content.bboard .iwant-h1.blue {
	margin-top: 105px;
}
.nav-quick {
  padding-left: 0;
  list-style: none;
  position: absolute;
  z-index: 99;
  width: 1170px;
  div {
    display: block;
    font-family: 'PT Sans Narrow', sans-serif;
    font-weight: bold;
    letter-spacing: .5px;
    padding-right: 15px;
    .col-md-2, .col-xs-4 {
      padding-right: 0px;
    }
    a {
      display: block;
      color: #fff;
      text-transform: uppercase;
      font-size: 1.2em;
      font-weight: bold;
      min-height: 62px;
      line-height: 1.2;
      .fa {
        opacity: .5;
        display: inline-block;
        float: left;
        font-size: 2.2em;
        padding: 10px 8px 8px 5px;
        margin-right: 6px;
        vertical-align: middle;
		transition: all 0.3s ease-in-out;
      }
      &:hover .fa {
        opacity: .75;
      }
      &.externalLink:after {
        content: "" !important;
        display: none;
      }
      span {
        display: inline-block;
        float: left;
        height: 50px;
        padding-top: 8px;
        width: 85px;
        font-size: .9em;
      }
    }
  }
}

//Department Menu
#dept-menu-toggle {
  display: block;
  position: absolute;
  right: 5px;
  font-size: 1.5em;
  color: #333;
  text-decoration:none !important;
}
#dept_menu {
  button {
    position: absolute;
    right: 2px;
    top: 255px;
    z-index: 100;
  }
  h1, h2 {
	background: #00584c;
    width: 320px;
    padding: 10px;
    position: absolute;
    right: 0;
    top: 230px;
    font-size: 1.3em;
    color: #fff !important;
  }
  ul.links {
    background: #fff;
    width: 320px;
	padding: 5px 0 0;
    position: absolute;
    right: 0;
    top: 290px;
  }
  li {
    background: #efefef;
    line-height: 1.2em;
	margin: 0px;
	padding: 5px 10px;
	border-bottom: 1px solid #ccc;
  }
  li:hover {
    background: #fff;
  }
  h1:before, h2:before {
    width: 80px;
    border-bottom: none !important;
  }
  a {
	text-decoration: none !important;
	display: block;
  }
  
}
  @media only screen and (max-width: 1199px) {
    .nav-quick {
      width: 969px;
      div {
        a {
          font-size: 1.1em;
          margin-bottom: 0px;
          min-height: 58px;
          line-height: 1.3;
          .fa {
            font-size: 2.0em;
            padding: 12px 0px 8px 5px;
            margin-right: 6px;
          }
          span {
            height: 40px;
            padding-top: 7px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 991px) {
    body.front .iwant-h1.blue, body.node-type-bboard-page #main .iwant-h1.blue {
      margin-top: 166px;
    }
    .nav-quick {
      width: 750px;
      div {
        a {
          font-size: 1.1em;
        }
      }
    }
    #dept-menu-toggle {
      span { display:none; }
    }
    #dept_menu{
      button {
        position: relative;
        margin-right: 6%;
        float: right;
        top: 175px;
      }
      h1, h2 {
        position: relative;
        top: 170px;
        padding: 10px;
        width: 90%;
        margin: auto;
        /*  font-size: 1.9em; background: rgba(255, 255, 255, 0.9) */
      }
      ul.links {
        background: rgba(255, 255, 255, 0.9);
        width: 90%;
        display: block;
        margin: 170px auto;
        position:relative;
        top:0;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    body.front .iwant-h1.blue, body.node-type-bboard-page #main .iwant-h1.blue {
      margin-top: 214px;
    }
    .nav-quick {
      width: 100%;
      div {
        a {
          font-size: 1.1em;
          min-height: 52px;
          line-height: 1.1;
          span{
            margin-left:5px;
          }
          .fa {
            font-size: 1.3em;
            padding: 12px 0px 6px 8px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 400px) {
    .nav-quick {
      div {
        a {
          font-size: 1.1em;
          min-height: 52px;
          line-height: 1.1;
          span{
            margin-left:5px;
          }
          .fa {
            font-size: 1.2em;
            padding: 12px 0px 6px 6px;
          }
        }
      }
    }
  }