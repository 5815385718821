// Styles for comment.
// -----------------------------------------------------------------------------

#comments {
  padding:20px;
  a{
    &.username {
        padding: 5px;
        display: inline-block;
        background: #f5f5f5;
        margin: 0 0 -1px 1px;
        text-transform: uppercase;
        border-top: solid 1px #00584C;
        text-decoration: none;
    }
  }
}