// Typography
// -----------------------------------------------------------------------------

// Import Google Fonts
@import url('//fonts.googleapis.com/css?family=Montserrat:400,700|PT+Sans+Narrow:400,700');

h1, h2 {
}
.spotlights-h1 { text-transform: uppercase; }
h1, h2, h3 { font-weight: bold; color: #9f481b; line-height: 1.2em; }
h1 { font-size: 42px;  }
h2 { margin-top: 1em;  }
h1, h2, h3.underline {
  position: relative; z-index : 1;
  margin-bottom: 20px; padding-bottom: 15px;
  color: #9f481b;
}
h1:before, h2:before, h3.underline:before { /* underline */
  content : ""; position: absolute;  left    : 0; bottom  : 0;
  height  : 6px; width   : 130px;  border-bottom: 8px solid #9f481b;
}
h2:before, h3.underline:before {
  border-bottom: 4px solid #9f481b;
}
h1.noline, h2.noline {
	padding-bottom: 0px; margin-bottom: 10px !important;
}
h1.noline:before, h2.noline:before {
	border-bottom: none !important; height: 0px; width: 0px; 
}

	@media(max-width:850px) {
    #page-header {
      .page-header {
        h1.title {
          font-size: 2em;
		  padding-bottom: 10px;
        }
        h1:before, h2.underline:before, h3.underline:before { border-bottom-width: 4px; width:90px }
      }
    }
  }
h5, .h5 {
    font-size: 17px;
    font-weight: bold;
}