.modal-body {
  min-height:400px;
}
#cludo-search-modal-inner {
  display:none !important;
}
#cludo-search-form {
  padding:0 10px;
  position:relative;
  button {
    position: absolute;
    color: #fff;
    font-size: 1.2em;
    background-color: rgba(0, 88, 76, 0.9) !important;
    margin: 15px 0 0px;
    height: 2.1em;
    padding: 6px 10px 10px 10px;
    &.cludo-search-query-clear {
      margin-left: 42px;
      background-color: rgba(100, 100, 100, 0.9) !important;
    }
  }
  > label {
      float: left;
      font-size: 1.7em;
      line-height: 2.6em;
      color: #333;
      margin-right:10px;
  }
/*  .search-label { display: none; } */
  #search-larimer {
    display:inline-block;
    width: 60%;
    font-size: 1.5em !important;
    height: 1.7em !important;
    margin-top: 15px !important;
  }
  ul.search_autocomplete {
    background-color:rgba(245,245,245,1) !important;
    margin-left:112px;
    width:58%;
    position:absolute;
    top:57px;
    z-index:500;
    list-style:none;
    padding-left:0 !important;
    li{
      padding:5px 10px;
      border-bottom:1px solid #eee;
      font-size:1.1em;
      &:hover{
        color: rgb(0,88,76);
        background-color:#fff !important;
      }
    }
  }
}

#cludo-search-results,.cludo-search-results {
  .cludo-banner {
    background: #005569;
    color:#eee;
    padding: 10px;
    margin-bottom:10px;
    a {
      color: #eee
    }
    h3 {
      line-height: 1em;
      color: #eee;
      font-size: 1.4em;
      margin: 10px 0;
    }
    p {
      a{
        content: "\f14c ";
        font-family: "FontAwesome";
      }
    }
  }
  a.filter-btn {
    background: #fff;
    color: #444 !important;
    font-weight: bold;
    margin-left: -10px;
    font-size: 1.2em;
    &:after {
      content: ":";
    }
  }
  ul {
    li.facet-list-item {
      list-style: none;
      background: 0 0;
      margin-left: 0;
      float: none;
      a {
        display: block;
        padding: 3px;
        background-color: #f5f5f5;
        margin: 3px;
        border: 1px solid #eee;
        &:hover {
          text-decoration: none;
          background: #585453;
          color: #fff;
        }
        &.active {
          background-color: #333;
          color: #fff;
          font-weight: bold;
        }
      }
    }
  }
}

.search-result-count {
  font-size: 1.3em;
  margin: 10px 5px 20px 5px;
}
#cludo-search-results,.cludo-search-results {
  ul {
    li.search-results-item {
	  transition: all 0.3s ease-in-out; 	
      margin-bottom: 10px;
      margin-top: 0px;
      background-color: #f5f5f5;
      display: block;
      width: 100%;
      padding: 15px 10px;
	  .file-type {
		text-transform: uppercase;
		font-size: .9em;
		display: block;
		margin-bottom: 5px;
		&:before { content: "["; }
		&:after { content: "]"; }
      }
	  p { margin-bottom: 0px; }
	  a {
        &:hover {
          text-decoration: none;
          color: #0b5c54;
		  h2 { text-decoration: underline !important; }
        }
/*        &:after {
          content: "\f14c  Read More";
          color:#0b5c54;
          text-transform: uppercase;
          text-align: right;
          display: block;
          margin-right: 20px;
          opacity: 1;
        }
*/
        h2 {
          margin: 0 !important;
          font-size: 1.2em;
          color: #0b5c54;
          &:before {
            display:none;
          }
		  &:after {
          content: " \f14c";
          font-family: "FontAwesome";
		  }
		}
        b {
          background: #fef498;
          padding: 2px;
        }
      }
     a[href$=".pdf"] h2:after,
      a[href$=".doc"] h2:after,
      a[href$=".docx"] h2:after,
      a[href$=".xls"] h2:after,
      a[href$=".xlsx"] h2:after,
      a[href$=".ppt"] h2:after,
      a[href$=".pptx"] h2:after {
        content: " \f019";
      }	  
    a[href$=".pdf"]:after,
      a[href$=".doc"]:after,
      a[href$=".docx"]:after,
      a[href$=".xls"]:after,
      a[href$=".xlsx"]:after,
      a[href$=".ppt"]:after,
      a[href$=".pptx"]:after {
        content: "";
        content: none;
      }
/* content: "\f019  Download File"; */
      &:hover {
        background-color: #e8f0ef;
      }
    }
  }
}
#cludo-load-more {
  display: block;
  margin-left:26%;
  button {
    background:#333;
    padding: 5px 30px;
  }
}

.cludo-footer {
  margin-top: 50px;
  text-align: right;
  font-size:0.9em;
  padding-bottom: 5px; 
}
@media only screen and (min-width: 768px) {
    .cludo-c-3 {
	  border-right: 1px solid #efefef;
	  min-height: 150px;
	}
	.cludo-categories { display: none; }
}
@media only screen and (max-width: 767px) {
  div#searchModaltitle {
    font-size: 22px !important;
  }
  #cludo-search-form {
    > label {
      font-size: 1.4em;
      line-height: 0.6em;
      display: none;
    }
    #search-larimer {
      font-size: 1em !important;
      height: 2.5em !important;
      width: 70%;
    }
    ul.search_autocomplete {
      margin-left:0px;
      width:70%;
      position:absolute;
      top:55px;
      }
  }
  #cludo-search-results,.cludo-search-results {
    .cludo-banner {
      h3 {
        font-size:1.2em
      }
    }
    a {
      &.filter-btn {
        background: #00584c;
        color: #fff !important;
        font-weight: 300;
        margin-left: 0px;
        font-size: 1em;
        &:after {
          content: "    \f0d8";
          font-family: "FontAwesome";
        }
        &.collapsed {
          &:after {
            content: "    \f0d7";
            font-family: "FontAwesome";
          }
        }
      }
    }
    .cludo-c-3 {
      webkit-box-flex: none;
      display: block;
      flex: none;
      width: 100%;
      max-width: 100%;
    }
    .cludo-c-9 {
      -webkit-box-flex: none;
      flex: none;
      width: 96% !important;
      max-width: 96%;
      display: block;
    }
    ul {
      li.facet-list-item {
        float: left;
        a {
          display: inline-block;
          padding: 2px 4px;
          background-color: #f5f5f5;
          margin: 2px;
          font-size: 0.8em;
          border: 1px solid #eee;
        }
      }
    }
  }
  .search-result-count {
    font-size: 1.1em;
    margin: 10px 5px;
  }
}