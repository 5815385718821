// Styles for Spotlights
// -----------------------------------------------------------------------------
z
.spotlights-h1 {
  color: #984d16;
  text-transform: uppercase;
  &:before{
    border-bottom-color: #984d16;
  }
}
.spotlights-h2, .view-natural-resources-calendar h2.gray { text-transform: uppercase; }
.node-spotlight-article {
  .row {
    .group-left{
      .field-label-inline {
        .field-label {
          text-align:left !important;
        }
      }
      img {
        width:100%;
        height:inherit;
        border-bottom: $brand-green solid 4px;
      }
    }
    .group-right{
      .captioned-photo {
        position:relative;
        float:right !important;
        padding-left:0;
        padding-right:0;
        width:350px;
        margin-left:10px;
        img {
          height:inherit;
        }
        span{
          box-sizing: border-box;
          background: rgba(0,0,0,0.5);
          color: #fff;
          position:absolute;
          bottom:0;
          display:block;
          padding:5px;
          font-size:0.8em;
          width:100%;
          a{
            color:#cbe4e0;
          }
        }
      }
      .field-item {
        &> p {
          padding-top: 0px;
          padding-bottom: 5px;
        }
      }
    }
  }
}
/* SPOTLIGHT STYLES */
.spotlights {
  content: "";
  display: table;
  width: 100%;
  clear: both;
  margin-bottom: 20px;
  .article {
    border-top: 6px solid #c7b48a;
    width: 100%;
    margin-top: 10px;	
    line-height: 1.5em;
    .article-image {
      float: left;
      width: 49%;
      img {
        width: 100%;
        height: auto;
      }
    }
	.article-text h2 a { text-decoration: none !important; }	  
    .article-text {
      padding: 10px 20px 10px 30px;
      min-height: 320px; 
      p {
        font-size: 16px;
        margin:0px;
        padding:7px 0;
        overflow: hidden;
      }
      h2 {
        font-size: 20px;
        display: block;
        font-family: Montserrat, sans-serif;
        font-weight:bold;
        margin-bottom: 5px;
        padding-bottom: 5px;
        content : "";
        border-bottom: none !important;
        &:before {
          height  : 0px; width   : 0px;  border-bottom: none;
        }
      }
      h3 {
        font-size: 17px;
        color: rgb(35, 31, 32);
        display: block;
        font-family: Montserrat, sans-serif;
        text-transform: uppercase;
        font-weight:300;
		margin-top: 12px;
      }
      a.btn {
        padding:10px;
        &.btn-info {
          border-color: transparent;
			text-transform: uppercase;
			font-size: .9em;
        }
        margin-top: 10px;
      }
    }
    &:first-of-type {
      content: "";
      display: table;
      clear: both;
      margin-top: 0px;
	  border-color: #c8b58d;
      background-color: #f0e7d6;
	  
      .article-text {
        float: left !important;
		display: inline-block !important;
		padding-left: 10px !important;	
        h2 {
          color: #585453;
          a,a:link,a:visited {
            color:#585453;
          }
        }
        a.btn {
            background: #585453;
            border-color: #585453;
        }
      }
    }
    &:nth-of-type(2), &:nth-of-type(3) {
      float: left;
      width: 49%;
      /* min-height: 325px; */
    }
    &:nth-of-type(2) {
      margin-right: 2%;
      border-color: #005569;
      background: #c5d8dc;
      .article-text {
        h2 {
          color: #005569;
          a,a:link,a:visited {
            color: #005569 !important;
          }
        }
        a.btn {
          background: #005569;
          border-color: #005569;
        }
      }
    }
    &:nth-of-type(3) {
      border-color: #9f481b;
      background: #e7d3c8;
      .article-text {
        h2 {
          color: #9f481b;
          a,a:link,a:visited {
            color: #9f481b !important;
          }
        }
        a.btn{
          background: #9f481b;
          border-color:#9f481b;
        }
      }
    }
  }
  &.secondspot {
    .article {
      &:first-of-type{
        .article-image{
            margin-right:25px;
        }
        .article-text{
          float:none;
          width:100%;
        }
      }
    }
  }
}

    .event-location, .article-department { margin-left: 10px; padding-left: 10px; border-left: 1px solid #999; display: inline-block; }
    .views-field-field-event-location { margin: 0 0 0 8px; padding: 0px 0 0px 8px; border-left: 2px solid #999; }
    .article-list { border-top: 4px solid #c7b48a; background: #e6e6e6; padding: 0 20px 10px 20px; margin-bottom: 10px;  }
    .article-list h4 { margin-top: 10px; }
    .article-thumb { float: left; margin: 0 30px 15px -20px; }
    .chosen-container-multi .chosen-choices {
      border: none;
      background-image: none;
    }
@media only screen and (min-width: 992px) {
	.spotlights .article:first-of-type .article-text {
		width: 48% !important;
	}
}
@media only screen and (max-width: 991px) {
  /* Spotlight Mobile Styles */
  .spotlights {
   .article:first-of-type .article-text {
		margin-left: 0px;
   }
    .article {
      .article-image {
        float: none;
        width: 100%;
        img {
          width: 100%;
          height: auto;
        }
      }
      &:nth-of-type(2), &:nth-of-type(3), &:first-of-type {
        float: none;
        width: 100%;
        .article-text {
          width: 100%;
          padding: 10px 20px 20px 20px;
		  min-height: auto !important; 
          a.btn {
            padding: 6px 10px;
          }
          &:first-of-type {
            margin-top: 0px;
          }
          &:nth-of-type(2) {
            margin-right: 0%;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 480px) {
  .group-right {
    .captioned-photo {
      float: none;
      height: inherit;
      width:100%;
      img {
        width:100%;
      }
    }
    .field-item {
      &> p {
      clear:both;
      }
    }
  }
}