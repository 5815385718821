div.horizontal-tabs {
  margin: 0 0 1em 0; /* LTR */
  padding: 0;
  border: 1px solid #ccc;
  position: relative; /* IE6/7 */
}

.horizontal-tabs ul.horizontal-tabs-list {
  display: inline-block;
  margin: 0;
  border: 0;
  padding: 0px;
  position: relative; /* IE6 */
  list-style: none;
  list-style-image: none; /* IE6 */
  background-color: #dedede;
  border-right: 1px solid #dedede; /* LTR */
  width: 100%;
  height: auto;
  clear: both;
}

.horizontal-tabs fieldset.horizontal-tabs-pane {
  padding: 0 1em;
  border: 0;
}

fieldset.horizontal-tabs-pane > legend,
fieldset.vertical-tabs-pane fieldset.horizontal-tabs-pane > legend {
  display: none;
}

/* Layout of each tab */
.horizontal-tabs ul.horizontal-tabs-list li {
  background: #eee;
  border-right: 1px solid #ccc; /* LTR */
  padding: 1px;
  padding-top: 0;
  margin: 0;
  min-width: 5em; /* IE7 */
  float: left; /* LTR */
}
.horizontal-tabs ul.horizontal-tabs-list li.selected {
  background-color: #fff;
  padding: 0 0 1px 0;
}
.horizontal-tabs ul.horizontal-tabs-list li a {
  display: block;
  text-decoration: none;
  padding: 0.5em 0.6em;
}
.horizontal-tabs ul.horizontal-tabs-list li a:hover {
  outline: none;
  background-color: #ededdd;
}
.horizontal-tabs ul.horizontal-tabs-list li:hover,
.horizontal-tabs ul.horizontal-tabs-list li:focus {
  background-color: #ddd;
}
.horizontal-tabs ul.horizontal-tabs-list :focus {
  outline: none;
}
.horizontal-tabs ul.horizontal-tabs-list li a:focus strong,
.horizontal-tabs ul.horizontal-tabs-list li a:active strong,
.horizontal-tabs ul.horizontal-tabs-list li a:hover strong {
  text-decoration: none;
  outline: none;
}
.horizontal-tabs ul.horizontal-tabs-list li a,
.horizontal-tabs ul.horizontal-tabs-list li.selected a {
  display: block;
  text-decoration: none;
  padding: 0.5em 0.6em 0.3em 0.6em;
  position:relative;
  top: 0px;
}
.horizontal-tabs ul.horizontal-tabs-list .selected strong {
  color: #000;
}
.horizontal-tabs ul.horizontal-tabs-list .summary {
  display: block;
}
.horizontal-tabs ul.horizontal-tabs ul.horizontal-tabs-list .summary {
  line-height: normal;
  margin-bottom: 0;
}

/**
 * tab content
 */
div.field-group-htabs-wrapper .field-group-format-wrapper {
  clear: both;
  padding: 0 0 0.6em;
}
/*hide*/
.horizontal-tabs .horizontal-tab-hidden {
  display: block;
  position: absolute;
  top: -100000px;
  width: 100%;
}
.sib-tabs { 
	font-family: 'PT Sans Narrow',sans-serif;
	font-weight: bold;
	font-size: 1.1em; 
}
.horizontal-tabs ul.horizontal-tabs-list li { min-width: 3em; }
div.horizontal-tabs.sib-tabs { border: none; }