// Helpers
// -----------------------------------------------------------------------------
.col-sm-6,.col-md-6,.col-xs-6,.col-sm-4,.col-md-4,.col-xs-4, .col-sm-3, .col-md-3,.col-xs-3 {
  margin-bottom:10px;
}
  // fix for safari bugs
.safari-mac {
  .col-sm-10,.col-md-10,.col-xs-10 {
    flex: 83%;
    flex-grow: 0;
    display:block !important;
  }
  .col-sm-8,.col-md-8,.col-xs-8 {
    flex: 66%;
    flex-grow: 0;
    display:block !important;
  }
  .col-sm-6,.col-md-6,.col-xs-6 {
    flex: 49%;
    flex-grow: 0;
    display:block !important;
  }
  .col-sm-4,.col-md-4,.col-xs-4 {
    flex: 33%;
    flex-grow: 0;
    display:block !important;
  }
  .col-sm-3,.col-md-3,.col-xs-3 {
    flex: 24%;
    flex-grow: 0;
  }

  .col-sm-2,.col-md-2,.col-xs-2 {
    flex: 16%;
    flex-grow: 0;
  }
  .nav-btn a, .nav-btn-desc a { 
	min-height: 80px; 
  }
  .elected-official-block {
	min-height: 600px;
  }
  .nav-photo2 .inner { min-height:380px; } 
  .nav-photo2.nav-photo-gray div a.top-half h3, .nav-photo2.nav-photo-gray div .inner { min-height: 65px; } 
}

@media only screen and (max-width: 1199px) {
	.safari-mac .nav-btn a { min-height: 100px; }
}
@media only screen and (max-width: 767px) {
	.safari-mac .nav-btn a { min-height: 40px; }
}