/* page element accordian */
.panel-heading .accordion-toggle:after {
  /* symbol for "opening" panels */
  font-family: FontAwesome;  /* essential for enabling Font Awesome */
  content: "\f078";
  float: right;
  color: grey;
}
.panel-heading .accordion-toggle.collapsed:after {
  /* symbol for "collapsed" panels */
  content: "\f054";    /* adjust as needed, taken from bootstrap.css */
}