// Styles for election results
// -----------------------------------------------------------------------------

div.election-result-listing {
  div.view-content {
    div.views-row {
      margin-bottom: 20px;
      a.result-link {
        border-top: 8px solid #ddd;
        background: #ddd;
        display: block;
        padding: 10px 10px;
        color: #333;
        text-decoration: none !important;
        h2 {
          margin: 0;
          color: #333;
          font-size: 1.3em;
          text-decoration: underline;
        }
        h2:before {
          border-bottom: none;
        }
        h3 {
          color: #444;
          font-size: 1.1em;
          margin-left: 10px;
        }
        p {
          margin-left: 10px
        }
        span {
          display: block;
          text-align: right;
          margin-right: 10px;
        }
        span:hover {
          color: #00584C;
        }
        &:hover {
          background: #f0e7d6;
          border-color: #c7b48a !important;
          transition: all 0.3s ease-in-out;
        }
        &.final_unofficial {
          color: #fff !important;
          background: #585453 !important;
          border-top: 8px solid #585453;

          span.result-type {
            font-weight: bold;
            font-size:1.3em;
          }
          h2, h3, p, span {
            color: #fff;
          }
          &:hover {
            border-color: #bbb !important;
          }
        }
        &.final {
          color: #fff !important;
          background: #222 !important;
          border-top: 8px solid #585453;

          span.result-type {
            font-weight: bold;
            font-size:1.3em;
          }
          span.result-type:before {
            content: "\f046   ";
            font-family: FontAwesome;
          }
          h2, h3, p, span {
            color: #fff;
          }
          &:hover {
            border-color: #bbb !important;
          }
        }
      }
    }
    a[href$=".pdf"]:after, a.link-pdf[href]:after, a.externalLink:after {
      content: "";
    }
  }
}