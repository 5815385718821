// Styles for fields.
// -----------------------------------------------------------------------------

.node-administrative-matters{
  .field-name-field-agenda-item-time {
    display:block;
  }

}

.chosen-container-single {
  border:none !important;
  padding:0;
  .chosen-single {
    line-height: 1.3em;
    height: 35px;
    font-size: 1.2em;
    padding-top: 8px;
  }
}

.chosen-container.chosen-container-single.state {
      display: block !important;
}

.field {
  .field-label {
    font-size: 1.1em;
    margin-top: 10px;
  }
}

a.btn.btn-info {
  background-color: $brand-primary;
  background: $brand-primary;
}