#translateModal a.list-group-item { padding: 6px 12px; }
#translateModal .modal-body { padding-bottom: 0px; }
.flag{display:inline-block;margin: 0px 10px 5px 0px;vertical-align: bottom;
position:relative;width:16px;height:11px;background:url('https://www.larimer.gov/sites/default/files/flag-sprites.png') no-repeat}
.flag.flag-es{background-position:-224px -33px} .flag.flag-mx{background-position:-96px -99px}
.flag.flag-mo{background-position:-224px -88px} .flag.flag-ch{background-position:-96px -22px}
.flag.flag-us{background-position:0 -154px} .flag.flag-nl{background-position:-240px -99px}  
.flag.flag-gu{background-position:-96px -55px} .flag.flag-fr{background-position:-80px -44px} 
.flag.flag-de{background-position:-32px -33px} .flag.flag-in{background-position:-64px -66px}
.flag.flag-jp{background-position:-224px -66px} .flag.flag-it{background-position:-144px -66px}
.flag.flag-kr{background-position:-80px -77px} .flag.flag-se{background-position:-208px -121px}
.flag.flag-pt{background-position:-256px -110px} .flag.flag-ru{background-position:-96px -121px} .flag.flag-ua{background-position:-224px -143px}
/* https://flag-sprites.com */