a[href*="."]:after, a.externalLink:after {
    display: inline-block;
    font-family: FontAwesome;
    font-weight: normal !important;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
	margin-left: 6px;
    opacity: 0.7;
    filter: alpha(opacity=70);
}
a.externalLink:after {
    content: "\f08e";
}
a[href$=".txt"]:after,
a.link-text[href]:after {
    content: "\f0f6"; /*Text*/
}
a[href$=".pdf"]:after,
a.link-pdf[href]:after {
    content: "\f1c1"; /*PDF*/
}
a[href$=".doc"]:after,
a[href$=".docx"]:after,
a.link-word[href]:after {
    content: "\f1c2"; /*Word*/
}
a[href$=".xls"]:after,
a[href$=".xlsx"]:after,
a.link-excel[href]:after {
    content: "\f1c3"; /*Excel*/
}
a[href$=".ppt"]:after,
a[href$=".pptx"]:after,
a.link-powerpoint[href]:after {
    content: "\f1c4"; /*Powerpoint*/
}
/*
a[href$=".gif"]:after,
a[href$=".jpg"]:after,
a[href$=".jpeg"]:after,
a[href$=".png"]:after,
a[href$=".bmp"]:after,
a[href$=".tif"]:after,
a.link-image[href]:after {
    content: "\f1c5"; 
}*/
a[href$=".zip"]:after,
a[href$=".zipx"]:after,
a[href$=".rar"]:after,
a[href$=".tar"]:after,
a[href$=".gz"]:after,
a[href$=".dmg"]:after,
a[href$=".iso"]:after,
a.link-archive[href]:after {
    content: "\f1c6"; /*Archive*/
}
a[href$=".wav"]:after,
a[href$=".mp3"]:after,
a[href$=".fla"]:after,
a[href$=".flac"]:after,
a[href$=".ra"]:after,
a[href$=".rma"]:after,
a[href$=".aif"]:after,
a[href$=".aiff"]:after,
a[href$=".aa"]:after,
a[href$=".aac"]:after,
a[href$=".aax"]:after,
a[href$=".ac3"]:after,
a[href$=".au"]:after,
a[href$=".ogg"]:after,
a[href$=".avr"]:after,
a[href$=".3ga"]:after,
a[href$=".flac"]:after,
a[href$=".mid"]:after,
a[href$=".midi"]:after,
a[href$=".m4a"]:after,
a[href$=".mp4a"]:after,
a[href$=".amz"]:after,
a[href$=".mka"]:after,
a[href$=".asx"]:after,
a[href$=".pcm"]:after,
a[href$=".m3u"]:after,
a[href$=".wma"]:after,
a[href$=".xwma"]:after,
a.link-audio[href]:after {
    content: "\f1c7"; /*Audio*/
}
a[href$=".avi"]:after,
a[href$=".mpg"]:after,
a[href$=".mp4"]:after,
a[href$=".mkv"]:after,
a[href$=".mov"]:after,
a[href$=".wmv"]:after,
a[href$=".vp6"]:after,
a[href$=".264"]:after,
a[href$=".vid"]:after,
a[href$=".rv"]:after,
a[href$=".webm"]:after,
a[href$=".swf"]:after,
a[href$=".h264"]:after,
a[href$=".flv"]:after,
a[href$=".mk3d"]:after,
a[href$=".gifv"]:after,
a[href$=".oggv"]:after,
a[href$=".3gp"]:after,
a[href$=".m4v"]:after,
a[href$=".movie"]:after,
a[href$=".divx"]:after,
a.link-video[href]:after {
    content: "\f1c8"; /*Video*/
}
a[href$=".css"]:after,
a[href$=".js"]:after,
a[href$=".py"]:after,
a[href$=".git"]:after,
a[href$=".py"]:after,
a[href$=".cpp"]:after,
a[href$=".h"]:after,
a[href$=".ini"]:after,
a[href$=".config"]:after,
a.link-code[href]:after {
    content: "\f1c9"; /*code*/
}
a[href$=".exe"]:after,
a[href$=".jar"]:after,
a[href$=".dll"]:after,
a[href$=".bat"]:after,
a[href$=".pl"]:after,
a[href$=".scr"]:after,
a[href$=".msi"]:after,
a[href$=".app"]:after,
a[href$=".deb"]:after,
a[href$=".apk"]:after,
a[href$=".jar"]:after,
a[href$=".vb"]:after,
a[href$=".prg"]:after,
a[href$=".sh"]:after,
a.link-exe[href]:after {
    content: "\f085"; /*Executable*/
}
.iwant3-links a.externalLink::after, .iwant2-links a.externalLink::after, .dropdown a.externalLink::after, .st-foot a.externalLink::after, a[href*="facebook"].externalLink::after, a[href*="twitter"].externalLink::after, a[href*="linkedin"].externalLink::after, a[href*="youtube"].externalLink::after, .navblock a.externalLink::after, .quick-links a.externalLink::after, a.more.externalLink::after, .alert-page a.externalLink::after, a.externalLink.no-icon::after, .navblock a::after,  a.no-icon::after {
    content: "" !important; display:none !important; }