#department-elected-official {
  display: block;
  position: absolute;
  margin-top: -252px;
  right: 0px;
  z-index: 10;
  img {
    width: 190px;
    margin-right: 40px;
    display: block;
    z-index: 10000 !important;
  }
  p.electedname {
    display: block;
    position: absolute;
    bottom: 22px;
    right: 0;
    white-space: nowrap;
    background: rgba(33, 33, 33, 0.7);
    color: #eee;
    padding: 5px 250px 5px 10px;
    font-weight: bold;
    font-size: 1.2em;
    cursor: pointer;
    z-index: -50 !important;
  }
  p.electedtitle {
    display: block;
    position: absolute;
    bottom: -10px;
    right: 0;
    white-space: nowrap;
    background: rgba(80, 80, 80, 0.8);
    color: #eee;
    padding: 5px 250px 5px 10px;
    z-index: -50 !important;
    min-width: 397px;
  }
  &.left {
    left: 0px !important;
    img {
      margin-left: 40px !important;
    }
    p.electedname {
      left:0 !important;
      padding: 5px 10px 5px 250px !important;
      width: 420px;
    }
    p.electedtitle {
      left:0 !important;
      padding: 5px 10px 5px 250px !important;
      width:520px;
    }
  }
}
@media only screen and (max-width: 992px) {
  #department-elected-official {
    margin-top: -186px;
    img {
      width: 140px;
    }
    p.electedname {
      padding: 5px 200px 5px 10px;
      font-size: 1.0em;
    }
    p.electedtitle {
      padding: 5px 200px 5px 10px;
      min-width: 325px;
    }
    &.left {
      p.electedname {
        padding: 5px 10px 5px 200px !important;
        width: 350px;
      }
      p.electedtitle {
        padding: 5px 10px 5px 200px !important;
        width: 450px;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
    #department-elected-official {
      margin-top: -146px;
      img {
        width: 110px;
      }
      p.electedname {
        padding: 5px 155px 5px 10px;
        font-size: 1.0em;
      }
      p.electedtitle {
        padding: 5px 155px 5px 10px;
        min-width: 279px;
      }
      &.left {
        p.electedname {
          padding: 5px 10px 5px 170px !important;
          width: 310px;
        }
        p.electedtitle {
          padding: 5px 10px 5px 170px !important;
          width: 410px;
        }
      }
    }
  }
