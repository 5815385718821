// Styles for Views
// -----------------------------------------------------------------------------

.letter_link_New,.letter_link_Staff_Review,.letter_link_Internal_Review {
  display:none !important;
}

/* add global underline to appease the accessibility gods */
#main a, #main a:visited, #main a:link, #footer a {
  text-decoration: underline;
}