#phonetagmain li a{
  display:block;
  text-align:center;
  margin-bottom:15px;
  background:#a0a0a0;
  background:rgba(255,255,255,0.6) !important;
  padding:6px 2px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; /* future proofing */
  -khtml-border-radius: 3px; /* for old Konqueror browsers */
  -webkit-box-shadow: 6px 7px 24px -6px rgba(0,0,0,0.75);
  -moz-box-shadow: 6px 7px 24px -6px rgba(0,0,0,0.75);
  box-shadow: 6px 7px 24px -6px rgba(0,0,0,0.75);
}

#phonetagmain li a:hover{
  background:#a0a0a0 !important;
  background:rgba(255,255,255,0.9) !important;
}

#phonetagmain {
  list-style: none;
  min-width:850px;
}

#phonetagmain h2{
  font-family: 'Montserrat', sans-serif;
  color:#333;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.2em;
  margin:10px 0 20px 0;
}

#phonetagmain #elections h2{
  margin-top:25px;
  margin-bottom:10px;
}

#phonetagmain #motorv h2{
  margin-top:25px;
  margin-bottom:10px;
}

#phonetagmain h2 span{
  font-size:1.2em;
}

#phonetagmain li{
  float:left;
}
#phonetagmain li a {
  /*
  height: 150px;
  min-width: 825px !important;
  */
  width:200px;
  min-height:200px;
  height:200px !important;
  padding-top: 15px;
  margin-right:10px;
  position:relative;
  text-align: center;
  color: #333;
}
#phonetagmain li a ul{
  color: #333;
  font-size:1em;
  font-weight:500;
  top:170px;
  left:40px;
  position:absolute;
  padding:0 10px;
  list-style:disc;
}
#phonetagmain li a ul li{
  font-size:1em;
  font-weight:bold;
  float:none;
  text-align: left !important
}
#phonetagmain li a img{
  /*
      float: left;
      padding-top: 37px;
  */
  top:45px;
  left:30px;
  position:absolute;
}
#phonetagmain #motorv a img,#phonetagmain #recording a img{
  margin-left:40px;
}
#phonetagmain #elections a img {
  margin-left:25px;
}

#phonetagmain #recording h2 span span {
  font-size:0.8em;
}

#phonetagmain li a > span{
  font-family: 'Montserrat', sans-serif;
  color:#333;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.2em;
  margin:10px 0;
  bottom:10px;
  left:60px;
  position:absolute;
}


/* you're a wizard harry ie. wizard styles */

.wizard,
.tabcontrol {
  display: block;
  width: 100%;
  overflow: hidden
}

.wizard a,
.tabcontrol a {
  outline: 0
}

.wizard ul,
.tabcontrol ul {
  list-style: none!important;
  padding: 0;
  margin: 0
}

.wizard ul>li,
.tabcontrol ul>li {
  display: block;
  padding: 0
}

.wizard>.steps .current-info,
.tabcontrol>.steps .current-info {
  position: absolute;
  left: -999em
}

.wizard>.content>.title,
.tabcontrol>.content>.title {
  position: absolute;
  left: -999em
}

.wizard>.steps {
  position: relative;
  display: block;
  width: 100%
}


.wizard>.steps .number {
  font-size: 1.429em
}

.wizard>.steps>ul>li {
  width: 25%
}

.wizard>.steps>ul>li,
.wizard>.actions>ul>li {
  float: left
}


.wizard>.steps a,
.wizard>.steps a:hover,
.wizard>.steps a:active {
  display: block;
  width: auto;
  margin: 0 .5em .5em;
  padding: 1em 1em;
  text-decoration: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px
}

.wizard>.steps .disabled a,
.wizard>.steps .disabled a:hover,
.wizard>.steps .disabled a:active {
  background: #eee;
  cursor: default
}

.wizard>.steps .current a,
.wizard>.steps .current a:hover,
.wizard>.steps .current a:active {
  background: #9f481b;
  color: #fff;
  cursor: default
}

.wizard>.steps .done a,
.wizard>.steps .done a:hover,
.wizard>.steps .done a:active {
  background: #b9928f;
  text-decoration: none !important; 
  color: #fff
}

.wizard>.steps .error a,
.wizard>.steps .error a:hover,
.wizard>.steps .error a:active {
  background: #ff3111;
  color: #fff
}

.wizard>.content {
  background: #eee;
  display: block;
  margin: .5em;
  min-height: 54em;
  overflow: hidden;
  position: relative;
  width: auto;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px
}

.wizard>.content>.body {
  float: left;
  position: absolute;
  width: 95%;
  height: 95%;
  padding: 2.5%
}

.wizard>.content>.body ul {
  list-style: disc!important
}

.wizard>.content>.body ul>li {
  display: list-item
}

.wizard>.content>.body>iframe {
  border: 0 none;
  width: 100%;
  height: 100%
}

.wizard>.content>.body input {
  display: block;
  border: 1px solid #ccc
}

.wizard>.content>.body input[type="checkbox"] {
  display: inline-block
}

.wizard>.content>.body input.error {
  background: #fbe3e4;
  border: 1px solid #fbc2c4;
  color: #8a1f11
}

.wizard>.content>.body label {
  display: inline-block;
  margin-bottom: .5em
}

.wizard>.content>.body label.error {
  color: #8a1f11;
  display: inline-block;
  margin-left: 1.5em
}

.wizard>.actions {
  position: relative;
  display: block;
  text-align: right;
  width: 100%
}

.wizard>.actions>ul {
  display: inline-block;
  text-align: right
}

.wizard>.actions>ul>li {
  margin: 0 .5em
}

.wizard>.actions a,
.wizard>.actions a:hover,
.wizard>.actions a:active {
  background: #9f481b;
  color: #fff;
  display: block;
  padding: .5em 1em;
  text-decoration: none !important;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px
}


.wizard>.actions .disabled a,
.wizard>.actions .disabled a:hover,
.wizard>.actions .disabled a:active {
  background: #eee;
  color: #9F481B;
}

.wizard>.loading {}

.wizard>.loading .spinner {}

.tabcontrol>.steps {
  position: relative;
  display: block;
  width: 100%
}

.tabcontrol>.steps>ul {
  position: relative;
  margin: 6px 0 0 0;
  top: 1px;
  z-index: 1
}

.tabcontrol>.steps>ul>li {
  float: left;
  margin: 5px 2px 0 0;
  padding: 1px;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px
}

.tabcontrol>.steps>ul>li:hover {
  background: #edecec;
  border: 1px solid #bbb;
  padding: 0
}

.tabcontrol>.steps>ul>li.current {
  background: #fff;
  border: 1px solid #bbb;
  border-bottom: 0 none;
  padding: 0 0 1px 0;
  margin-top: 0
}

.tabcontrol>.steps>ul>li>a {
  color: #5f5f5f;
  display: inline-block;
  border: 0 none;
  margin: 0;
  padding: 10px 30px;
  text-decoration: none
}

.tabcontrol>.steps>ul>li>a:hover {
  text-decoration: none
}
#phonetag-steps-t-3 {
  display:none !important;
}

.tabcontrol>.steps>ul>li.current>a {
  padding: 15px 30px 10px 30px
}

.tabcontrol>.content {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 35em;
  overflow: hidden;
  border-top: 1px solid #bbb;
  padding-top: 20px
}

.tabcontrol>.content>.body {
  float: left;
  position: absolute;
  width: 95%;
  height: 95%;
  padding: 2.5%
}

.tabcontrol>.content>.body ul {
  list-style: disc!important
}

.tabcontrol>.content>.body ul>li {
  display: list-item
}


@media(max-width:600px) {
  .wizard>.steps>ul>li {
    width: 95%;
    float:none;
  }
  .wizard>.steps a,
  .wizard>.steps a:hover,
  .wizard>.steps a:active {
    margin-top: .5em
  }
}