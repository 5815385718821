// Page Layout
// -----------------------------------------------------------------------------

iframe[src*="youtube"], iframe[src*="vimeo"] { max-width: 100%; }
// Environmental banner


div.currentenvironment {
	color: inherit;
	text-align: center;
	padding: 0px 10px 0;
	position: absolute;
	background: rgba(38,38,38,0.7);
	width: 210px;
	right: 0px;
	margin-top: 101px; 
	color: #fff;
}
@media only screen and (max-width: 992px) {
	div.currentenvironment {
		margin-top: 81px;
	}
}
div.currentenvironment a,div.currentenvironment a:visited, div.currentenvironment a:hover {color: #fff !important; text-decoration: underline !important; }
div.currentenvironment a:hover {text-decoration: none !important; }
div.currentenvironment span {
	font-style: italic;
	color: #fff;
}

.field-collection-container {
	h4.label-above {
		font-size:18px;
	}
}
/* image align styles */
span[data-picture-align="right"], img[data-picture-align="right"] {
    float: right; padding: 0 0 20px 20px; width: 400px;
}
span[data-picture-align="left"], img[data-picture-align="left"] {
    float: left; padding: 0 40px 20px 0; width: 400px;
}
@media only screen and (max-width: 768px) {
	span[data-picture-align="right"], img[data-picture-align="right"] {
		float: right; padding: 0 0 20px 20px; width: 250px;
	}
	span[data-picture-align="left"], img[data-picture-align="left"] {
		float: left; padding: 0 30px 20px 0; width: 250px;
	}
}
@media only screen and (max-width: 540px) {
	span[data-picture-align="right"], img[data-picture-align="right"],
	span[data-picture-align="left"], img[data-picture-align="left"] {
		float: none; padding: 10px; width: auto;
	}
}

/* colors:
	green: #00584C  ltgreen: #4c8b82
	tan: #7a673c	lttan: #F0E7D6 dark-tan: #AE9256 // was c7b48a
	blue: #005569	ltblue: #C5D8DC		50% #448291 	25%	#72A1AB
	sienna: #9f481b ltsienna: #E7D3C8
	brown: #585453
	gray: #231F20	ltgray: #585453 #8d8785 #E6E6E6
	FONTS: Montserrat PT Sans Narrow // Open Sans Condensed
*/

body { min-width: 320px; width: auto !important;
	font-family: 'Montserrat', sans-serif; color: #231F20; font-size: 16px;
}
body, html { height: 100%; }
/* a, a:hover, a:active, a:focus { outline: 0; } */
.breadcrumb { font-family: 'PT Sans Narrow'; }
.breadcrumb > .active, .text-muted, .help-block { color: #666 !important; }
:target:before {
	content:"";
	display:inline-block;
	height:90px; /* fixed header height*/
	margin:-90px 0 0; /* negative fixed header height */
	visibility: hidden;
}

.pull-right-margin { margin: 0 0 20px 20px; }

#broadcastlink {
	text-decoration: none;
	background-color: rgba(15,15,15,0.7);
	color: #fff;
	display: block;
	float: left;
	margin-top: 5px;
	padding: 5px;
	margin-left: 5px;
}

nav, .st-foot, button, .iwant3, #google_translate_element select, .nav-photo, .nav-photo2, .nav-icon, .nav-btn {
	font-family: 'PT Sans Narrow', sans-serif; font-weight: bold; letter-spacing: .5px; } /* , .pullout-photo */
.st-foot, #google_translate_element select { font-weight: normal; font-size: 18px; }
.st-foot h3 { font-family: 'Montserrat', sans-serif; }
.st-foot h3 { font-weight: bold; }

.container.main-content { margin-top: -45px; background: #fff; padding-top: 16px; padding-bottom: 40px; }
body.not-front .container.main-content {margin-top:0 !important;}
body.page-node-5450 .container.main-content.bboard, body.page-node-8886 .container.main-content.bboard {margin-top: -45px !important; } 
.page-node-8886 .carousel-control { padding-top: 150px; padding-bottom: 130px; }

.node-type-bboard-page .iwant2 { min-height: 258px; }
body.page-node-8886.node-type-bboard-page .iwant2 { min-height: 262px; }
@media only screen { 
body.page-node-8886 .iwant ul, .iwantto .iwant ul { height: 236px; } 
}
/* HEADINGS */
.page-header {
	padding-bottom: 10px;
	margin: 0px 0 22px;
	border-bottom: none;
}

.page-header.titleicon {
	padding-left: 75px;
	background-position: 0 10px !important;
	background-size: 56px !important;
}
ul.action-links { min-height: 30px; }

/* indents from ckeditor */
.region-content {
	.field-name-body {
		.rteindent1 {
			margin-left: 1cm !important;
		}
		.rteindent2 {
			margin-left: 2cm !important;
		}
		.rteindent3 {
			margin-left: 3cm !important;
		}
		.rteindent4 {
			margin-left: 4cm !important;
		}
		p.rteleft {
			text-align: left !important;
		}
		p.rteright {
			text-align: right !important;
		}
		p.rtecenter {
			text-align: center !important;
		}
		p.rtejustify {
			text-align: justify !important;
		}
	}
}

/*added section for form and submit info */
.webform-submit.button-primary.form-submit.btn.btn-default.btn-primary{
	margin-top:100px;
}

/* BOOTSTRAP OVERRIDES */
table td { cursor: text !important; }
img { max-width: 100%; min-height: 1px; height: auto; }
@media only screen and (min-width: 768px) {
	.main-content .row:not(.nav-quick):not(.assessor):not(.inner) {
		display: flex;
		flex-wrap: wrap;
		display: -webkit-box;
		display: -webkit-flex;
		/* display: -ms-flexbox;
		display: flex; */
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.main-content .row:not(.nav-quick):not(.assessor):not(.inner) > [class^='col-'] {
		display: -webkit-box;
		display: -webkit-flex;
		/* display: -ms-flexbox;
		display: flex; */
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.main-content .row.assessor, .main-content .row.inner [class^='col-'] {
		display: initial !important;
	}
}

.main-content table {
	min-height: .01%; overflow-x: auto; /*.table-responsive */
	width: 100% !important; max-width: 100%; margin-bottom: 20px;
	height: auto !important; border-color: #fff;
	border-collapse: collapse !important;
	border-left: 1px solid #ddd;
	border-right: none !important;
	border-top: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
}

.main-content table td.top-row,
.main-content table th,
.main-content table thead > tr > td,
.main-content table tfoot > tr > td {
	background-color: #ccc;
	vertical-align: bottom;
	font-size: .9em;
	padding: 15px 10px;
	text-align: auto;
}
.main-content table td { vertical-align: top; padding: 6px 10px; text-align: auto; }
.main-content table td.top-row,
.main-content table th,
.main-content table tfoot > tr > td,
.main-content table td {
	border-collapse: collapse !important;
	border-top: 1px solid #ddd;
	border-right: 1px solid #ddd;
	border-left: none !important;
	border-bottom: none !important;
}
.main-content table tr:nth-of-type(2n+1) td {
	background-color: #f9f9f9;
}
.main-content  table:not(.full) tr:hover td, .main-content table tr:hover:nth-of-type(2n+1) td {
	background-color: #fdefd0;
}
.main-content table.table-condensed td, .main-content table.table-condensed th {
	font-size: .85em !important;
	padding: 10px 5px !important;
}

pre{
	display: block;
	font-family: 'Montserrat', sans-serif;
	padding: none;
	margin: 0 0 15px;
	font-size: 1em;
	color: #333;
	word-break: break-all;
	word-wrap: break-word;
	background-color: none !important;
	border: none;
	border-radius: none !important;
	overflow: visible;
}

.pullout-photo, .pullout-profile, .pullout-med {
	.col-sm-6, .col-md-6, .col-xs-6, .col-sm-4, .col-md-4, .col-xs-4, .col-sm-3, .col-md-3, .col-xs-3 {
		margin-bottom: 0px; 
	}
}

.field .field-label { margin-top: 0px !important; font-size: 1em !important; }
.field { margin-top: 10px; }
.label-default { background-color: #595959; }
.view-display-id-upcoming_by_location .views-row.col-md-6, .view-id-natural_resources_calendar .views-row.col-md-6 { margin-bottom: 20px; }
.views-field-field-event-date-2, .views-field-field-event-location { font-family: 'PT Sans Narrow';
font-size: 1.15em; } 
.views-field-field-event-location:empty { display: none; } 
.view-natural-resources-calendar .col { padding-bottom: 10px !important; }
.node-event span.date-display-single, .node-event div.date-display-remaining {
    display: block; margin-left: 180px;
}
.node-event div.date-display-remaining { font-size: .9em; color: #9f481b; }
.node-event span.date-display-single:first-of-type {
    display: inline-block; margin-left: 0px;
}
.node-event span.date-display-single div.date-display-remaining {
    margin-left: 0px !important;
}
.node-event .field-name-field-location-name, .webform-progressbar { display: none; }
.page-events a.nav-link.upcoming,  a.nav-link.upcoming:hover, 
.page-events-year a.nav-link.year,  a.nav-link.year:hover, 
.page-events-month a.nav-link.month,  a.nav-link.month:hover, 
.page-events-week a.nav-link.week,  a.nav-link.week:hover, 
.page-events-day a.nav-link.day,  a.nav-link.day:hover 
{
    border: none !important;
    background-color: #00584C !important;
    text-decoration: none !important;
    color: #fff;
}
.view-display-id-upcoming_events .views-row {
	overflow: hidden !important;
}

.view-display-id-upcoming_events .views-row .views-field {
	height:100% !important
}
.node-event .field-name-field-park-location h2 { display: none; }

ul.rrssb-buttons li { width: auto !important; }
.rrssb .rrssb-text { padding: 0 1em 0 4em; }
ul.rrssb-buttons a { width: 37px; }
#main ul.rrssb-buttons a, #main ul.rrssb-buttons a:visited, #main ul.rrssb-buttons a:link, #main ul.rrssb-buttons a:hover { text-decoration: none !important; }
.rrssb-buttons li.rrssb-email a { background-color: #0058af; }
.rrssb-buttons li.rrssb-twitter a { background-color: #097390; }
.rrssb-buttons li.rrssb-googleplus a { background-color: #a41e12; }

.chosen-choices li.search-choice, .chosen-container-multi .chosen-choices li.search-choice, .chosen-container-single .chosen-single { background-image: none !important; border-radius: 0px; box-shadow: none; }
.chosen-container-single .chosen-drop { border-radius: 0px; }
.chosen-choices:hover, .chosen-container .chosen-drop  { box-shadow: none; }
.chosen-results .active-result, .search-result { background: #F2F2F2; padding: 5px; margin-bottom: 10px;  }
.chosen-results .active-result.highlighted { background:#9f481b !important; }
.search-result span.text-info {background:#fef498;padding:2px}
.search-result h4 { font-weight: bold; }
.search-result h4 span { font-weight: normal !important; }
.view-search-block .view-empty { margin-top: 25px; }
.view-search-block select { width: 180px; }
.view-search-block h4 { text-transform: capitalize; }
#searchModal .views-exposed-widget label { vertical-align: top; padding-top: 7px; }
/*.list-group-item, .list-group-item:last-child { margin-bottom: 6px; } */
.list-group-item:last-child { margin-bottom: -1px; }
a.list-group-item { text-decoration: none !important; }
.main-content p { padding: 0px; margin: 0 0 15px; line-height: 1.5em; }
.main-content li { line-height: 1.5em; margin-bottom: 5px; }
p+ul, p+ol { margin-top: 0px; }
.main-content .faqs li, .main-content .chart-legend li, .main-content .no-bot-margin li, .main-content .info-window li, .main-content .map-nav li, .main-content .dropdown-menu li, .main-content .pie-legend li, .main-content td li, .main-content .iwant3 li { margin-bottom: 0px !important; }
.main-content img { max-width: 100%; }
.offero-img, .event-img { float: right; margin: 0 0 20px 20px; max-width: 300px !important; }
.pull-right.col-sm-6 { padding-right: 0px; }
.flex-caption { text-shadow: none; padding: 10px; }
page-header { padding: 0px; margin: 0px; }
.action-links { text-align: right; clear: both; }
.action-links > li { float: right; }
.webform-datepicker .chosen-container.month  {
	width: 100px !important;
}
.webform-datepicker .chosen-container.day, .webform-datepicker .chosen-container.year {
	width: 80px !important;
}
.label-count {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}


.chosen-container.hour, .chosen-container.minute { width: 100px !important; }
.form-radios .form-item { display: inline-block; margin-right: 20px; }
.form-checkboxes .form-item, .form-radios .form-item { margin-bottom: 10px; }
.file { display: inline-block; }
.list-group .col-sm-6 { margin-bottom: -1px !important; } 
.nav-pills { margin-bottom: 15px; }
ul.nav.nav-pills li > a {
    padding: 10px 15px !important;
}
ul.nav.nav-pills.smaller li > a { padding: 6px 8px !important; font-size: .9em; }
.views-exposed-form {
    background: #e1ebea;
    padding: 5px 10px 2px 10px;
    border-top: 4px solid #00584c;
    margin-top: 10px;
    margin-bottom: 20px;
	margin-left: 0px;
}

a:focus, a:hover {
	color: #231F20;
	text-decoration: underline;
}
a {
	color: #00584C;
	text-decoration: none;
}

.breadcrumb {
	padding: 0px;
	margin-bottom: 20px;
	list-style: none;
	background-color: transparent;
	border-radius: 0px;
}
.breadcrumb.make-abs {
	position: absolute;
	width: 95%;
}
.breadcrumb-abs { height: 50px; }
.horizontal-tabs ul.horizontal-tabs-list {  background-color: #fff !important; border-right: none !important; }
.horizontal-tabs fieldset.horizontal-tabs-pane { padding: 0px; }
.horizontal-tabs-list a {
	text-decoration: none !important;
}
div.horizontal-tabs {
	border-top: none;
}
.horizontal-tabs ul.horizontal-tabs-list li {
	border-top: 3px solid #DEDEDE;
	border-bottom: 1px solid #DEDEDE;
	min-width: 2em;
}
.horizontal-tabs ul.horizontal-tabs-list li a:hover {
	outline: none;
	background-color: transparent;
}
.horizontal-tabs ul.horizontal-tabs-list li:hover {
	outline: none;
	background-color: #E1EBEA;
}
.horizontal-tabs ul.horizontal-tabs-list li.selected,
.horizontal-tabs ul.horizontal-tabs-list li:hover.selected {
	border-top: 3px solid #00584C;
	border-bottom: 1px solid #fff;
	background-color: #fff;
}
.panel { box-shadow: none; }

ul.nav.nav-pills > li > a:link,ul.nav.nav-pills > li > a { border: 1px solid #ccc !important; background-color: #fff; text-decoration: none !important; }
ul.nav.nav-pills > li > a:hover { background-color: #efefef; text-decoration: none !important; }
ul.nav.nav-pills > li.active > a:link, ul.nav.nav-pills > li.active > a,  ul.nav.nav-pills > li.active > a:hover, a.nav-link active { border: none; background-color: #00584C; text-decoration: none !important; }
.nav.nav-pills.autonav>li>a { padding: 6px 10px; font-size: .95em; }
.bg-primary, .btn-primary { color: #FFF; background-color: #00584C; }
.btn-primary, .btn, a.btn, .btn a { color: #FFF; background-color: #00584C; border-color: #00584C; border: none; text-decoration:  none !important; margin-bottom: 3px; }
td .btn-primary, td .btn, td .btn a { margin-bottom: 0px; }
.btn-primary.active,.btn-primary.active:active, .btn-primary:active, .btn:active, a.btn:active, .btn a:active  { background-color: #400101; color: #fff; }
.btn:hover, .btn:focus, .btn.focus { color: #fff !important; }
.btn-primary:hover, .btn:hover, a.btn:hover, .btn a:active { background-color: #00584C; color: #fff; text-decoration: underline !important; }
.btn-primary.disabled,  .btn-primary[disabled] { background-color: #993333; }

.well {
    box-shadow: none;
}

.nav-photo a, .nav-photo2 a, .nav-icon a, .nav-btn a, .nav-btn-desc a, .iwant a { text-decoration: none !important; }
.main-content a:hover, .nav-photo a:hover, .nav-photo2 a:hover, .nav-icon a:hover, .nav-btn a:hover, .iwant a:hover { text-decoration: underline; }
.nav-photo2 .inner, .nav-btn-desc a, .nav-photo2.nav-photo-gray div .inner, .nav-photo a { height: 100%; }
.nav-photo2 a:hover h3 { color: #fff; }
.modal { z-index: 10051; }
.modal-header { padding: 10px; color: #fff; background-color: #0B5E55; }
.modal-header h3 { font-size: 1.2em; }
.modal-md { width: 800px; }
@media screen and ( max-width: 840px ){
	.modal-md { width: 100%; max-width: 92%; }	
}
thead > tr > td.top-row { padding: 15px 5px; }
tbody > tr:hover { cursor:pointer;}
th.thspan, td.thspan  { border-left: 1px solid #ccc; }
/* bootstrap modal extra scrollbar fix */
#detailModal{ overflow:hidden; }
body { overflow-y: scroll !important; }
.modal .modal-body { overflow-y: auto; }
.owner-info h4 { margin-bottom: 0px; }
.modal-lg { width: 95%; }
.modal .modal-body { max-height: 350px; }

.link-icon { display: inline-block; float: right; margin: -5px -5px 5px 5px; background: none !important; color: #231F20; }
.location-locations-header { display: none; }
.location.vcard { margin-bottom: -15px; }
.node-type-event .location.vcard { margin-bottom: 15px; }
.location.vcard .fn { font-size: 1.2em; }
.btn-primary, body.maintenance-page .form-actions .btn:first-child, .btn, a.btn, .btn a {
	color: #FFF !important;
}

.no-top { margin-top: 0px !important; }
.no-bottom { margin-bottom: 0px !important; }
ul.circle {list-style-type: circle;}
ul.square {list-style-type: square;}
ol.upper-roman {list-style-type: upper-roman;}
ol.lower-roman {list-style-type: lower-roman;}
ol.upper-alpha {list-style-type: upper-alpha;}
ol.lower-alpha {list-style-type: lower-alpha;}
ul.checklist li:before { font-family: 'FontAwesome'; content: '\f058'; margin:0 10px 0 -10px; color: #00584C; }
ul.checklist { list-style-type: none; margin-left: 20px; }
ul.checklist.square li:before { content: '\f14a'; }
ul.checklist.check li:before { content: '\f00c'; }
ul.checklist.large { font-size: 1.2em; }
ul.checklist li { text-indent: -20px; }

/* sheriff color set*/
div#main.enforcementblue h1, div#main.enforcementblue h2, div#main.enforcementblue h3, div#main.enforcementblue h4 {
	color: #204166;
}

div#main.enforcementblue h1:before, div#main.enforcementblue h2:before, div#main.enforcementblue h3.underline:before{
	border-color: #204166;
}

div#main.enforcementblue .nav-btn a .fa, div#main.enforcementblue .nav-btn-desc a .fa {
	color: #204166;
}

div#main.enforcementblue .nav-btn a, div#main.enforcementblue .nav-btn-desc a {
	background: #eee;
	color: #204166;
	border-top: 4px solid #204166;
}

div#main.enforcementblue h3.underline {
	color: #204166;
}

div#main.enforcementblue .alert-page.enforcementblue .fa {
	color: #ddd;
	background: #204165;
}

div#main.enforcementblue .alert-page.enforcementblue, div#main.enforcementblue .alert-page.enforcementblue a:hover {
	background: #eeeeee;
	color: #204166;
}

div#main.enforcementblue .pullout-photo.div-enforcementblue {
	border-color: #204166;
	background-color: #eeeeee !important;
}
div#main.enforcementblue .pullout-photo.div-enforcementblue a:link,div#main.enforcementblue .pullout-photo.div-enforcementblue a:visited{
	border-color: #204166;
}

div#main.enforcementblue a {
	color: #204166 !important;
}

div#main.enforcementblue a.btn, div#main.enforcementblue button.btn {
	color: #fff !important;
	background: #204166;
}
div#main.enforcementblue a.btn.btn-white, div#main.enforcementblue button.btn.btn-white {
    color: #204166 !important;
}
div#main.enforcementblue ul.nav.nav-pills > li.active > a:link, div#main.enforcementblue ul.nav.nav-pills > li.active > a, div#main.enforcementblue ul.nav.nav-pills > li.active > a:hover {
	border: none;
	background-color: #204166 !important;
	color:#fff !important;
}

div#main.enforcementblue .pullout-photo.div-green {
	border-color: #204166 !important;
	background-color: #ddd !important;
}

div#main.enforcementblue .spotlights .article:nth-of-type(2),div#main.enforcementblue .spotlights .article:nth-of-type(3) {
	border-color: #204166 !important;
	background: #ddd !important;
}

div#main.enforcementblue {
	.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
		background-color: #204166 !important;
	}
}

div#main.enforcementblue .spotlights .article:nth-of-type(2) .article-text a.btn,div#main.enforcementblue .spotlights .article:nth-of-type(3) .article-text a.btn {
	background: #204166;
	border-color: #204166;
}
div#main.enforcementblue .pagination .active a { color: #fff !important; background: #204166; }
div#main.enforcementblue .nav-btn-desc a:hover  { background: #F0E7D6 !important; }
div#main.enforcementblue #dept_menu h1, div#main.enforcementblue #dept_menu h2 { background: #204166; }

/* colo extension green */

div#main.extensiongreen h1, div#main.extensiongreen h2, div#main.extensiongreen h3, div#main.extensiongreen h4 {
	color: #014c26;
}

div#main.extensiongreen h1:before, div#main.extensiongreen h2:before, div#main.extensiongreen h3.underline:before{
	border-color: #014c26;
}

div#main.extensiongreen .nav-btn a .fa, div#main.extensiongreen .nav-btn-desc a .fa {
	color: #014c26;
}

div#main.extensiongreen .nav-btn a, div#main.extensiongreen .nav-btn-desc a {
	background: #eee;
	color: #014c26;
	border-top: 4px solid #014c26;
}

div#main.extensiongreen h3.underline {
	color: #014c26;
}

div#main.extensiongreen .alert-page.extensiongreen .fa {
	color: #ddd;
	background: #014c26;
}

div#main.extensiongreen .alert-page.extensiongreen, div#main.extensiongreen .alert-page.extensiongreen a:hover {
	background: #eeeeee;
	color: #014c26;
}

div#main.extensiongreen .pullout-photo.div-extensiongreen {
	border-color: #014c26;
	background-color: #eeeeee !important;
}
div#main.extensiongreen .pullout-photo.div-extensiongreen a:link,div#main.extensiongreen .pullout-photo.div-extensiongreen a:visited{
	border-color: #014c26;
}

div#main.extensiongreen a {
	color: #014c26 !important;
}

div#main.extensiongreen a.btn, div#main.extensiongreen button.btn {
	color: #fff !important;
	background: #014c26;
}
div#main.extensiongreen a.btn.btn-white, div#main.extensiongreen button.btn.btn-white {
    color: #014c26 !important;
}
div#main.extensiongreen ul.nav.nav-pills > li.active > a:link, div#main.extensiongreen ul.nav.nav-pills > li.active > a, div#main.extensiongreen ul.nav.nav-pills > li.active > a:hover {
	border: none;
	background-color: #014c26 !important;
	color:#fff !important;
}

div#main.extensiongreen .pullout-photo.div-green {
	border-color: #014c26 !important;
	background-color: #ddd !important;
}

div#main.extensiongreen .spotlights .article:nth-of-type(2),div#main.extensiongreen .spotlights .article:nth-of-type(3) {
	border-color: #014c26 !important;
	background: #ddd !important;
}

div#main.extensiongreen {
	.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
		background-color: #014c26 !important;
	}
}

div#main.extensiongreen .spotlights .article:nth-of-type(2) .article-text a.btn,div#main.extensiongreen .spotlights .article:nth-of-type(3) .article-text a.btn {
	background: #014c26;
	border-color: #014c26;
}
div#main.extensiongreen .pagination .active a { color: #fff !important; background: #014c26; }
div#main.extensiongreen .nav-btn-desc a:hover  { background: #F0E7D6 !important; }
div#main.extensiongreen #dept_menu h1, div#main.extensiongreen #dept_menu h2 { background: #014c26; }

div#main.extensiongreen .row.navblock.nav-photo2 h3, div#main.extensiongreen .row.navblock.nav-photo2 a {
    color: #fff !important;
}

/* social media updates */

.rrssb {
	margin-top: 50px;
	font-family: inherit !important;
}
.rrssb-icon { background-repeat: no-repeat !important; }
.card-group .field-item {
    padding: 3px 5px;
    background: #efefef;
}

@media screen and ( min-height: 300px ){
	.modal .modal-body { max-height: 250px; }
}
@media screen and ( min-height: 350px ){
	.modal .modal-body { max-height: 300px; }
}
@media screen and ( min-height: 400px ){
	.modal .modal-body { max-height: 350px; }
}
@media screen and ( min-height: 450px ){
	.modal .modal-body { max-height: 400px; }
}
@media screen and ( min-height: 500px ){
	.modal .modal-body { max-height: 450px; }
}
@media screen and ( min-height: 550px ){
	.modal .modal-body { max-height: 480px; }
}
@media screen and ( min-height: 600px ){
	.modal .modal-body { max-height: 530px; }
	#gmap2 { height: 350px; }
}
@media screen and ( min-height: 650px ){
	.modal .modal-body { max-height: 580px; }
	#gmap2 { height: 400px; }
}
@media screen and ( min-height: 700px ){
	.modal .modal-body { max-height: 650px; }
	#gmap2 { height: 450px; }
}
@media screen and ( min-height: 800px ){
	.modal .modal-body { max-height: 700px; }
	#gmap2 { height: 500px; }
}
.bold { font-weight: bold; }
.wages { width: 220px; margin-left: 10px; margin-top: 10px; padding: 5px; border: 1px solid #ccc; line-height: 165%; font-size: .9em; }
.wages hr { margin: 5px; }
.wages .bold { display: inline-block; width: 110px; text-align: right; padding-right: 10px; }
.pre h3 { margin-bottom: 0px; }
.popover-content { font-size: .85em; }
.popover { width: 250px; z-index: 1060; }
.red, .form-required { color: #9f481b !important; font-weight: bold; }
.alert .close { color: #000 !important; }
.table-striped > tbody > tr:nth-of-type(2n+1).sel, .table-striped > tbody > tr:hover.sel, .sel, tr:hover.sel td, .table-striped > tbody > tr:active.sel, .sel, tr:active.sel td, .main-content table tr.sel:nth-of-type(2n+1) td, .main-content table tr.sel:hover:nth-of-type(2n+1) td { background-color: #9f481b !important; color: #fff !important; }
.sel a { color: #fff !important; }
.table-striped > tbody > tr:nth-of-type(2n+1).sel .red, .table-striped > tbody > tr.sel .red, .table-striped > tbody > tr:hover.sel .red { color: #fff; }
.label1 { text-align: right; padding: 5px; font-weight: bold; }
.webform-container-inline div, .webform-container-inline div.form-item {
    display: inline;
}
.webform-datepicker.webform-container-inline div, .webform-datepicker.webform-container-inline div.form-item {
	float: left; 
}
.webform-datepicker select.form-control, select.hour.form-control, select.minute.form-control { width: 80px !important; }
.form-control { 
display: block;
width: 100%;
height: 36px;
padding: 6px 12px;
font-size: 16px;
line-height: 1.42857;
color: #555555;
background-color: #fff;
background-image: none;
border: 1px solid #ccc;
    border-top-color: rgb(204, 204, 204);
    border-right-color: rgb(204, 204, 204);
    border-bottom-color: rgb(204, 204, 204);
    border-left-color: rgb(204, 204, 204);
border-radius: 0px;
box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
transition: border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s;
}
.form-control.input-45 { width: 450px; display: inline-block; }
.form-control.input-35 { width: 350px; display: inline-block; }
.form-control.input-30 { width: 300px; display: inline-block; }
.form-control.input-25 { width: 250px; display: inline-block; }
.form-control.input-20 { width: 200px; display: inline-block; }
.form-control.input-15 { width: 150px; display: inline-block; }
.form-control.input-12 { width: 120px; display: inline-block; }
.form-control.input-10 { width: 100px; display: inline-block; }
.form-control.input-6 { width: 60px; display: inline-block; }
.form-control:focus {
    border-color: #54ada1;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(84, 173, 161, 0.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(84, 173, 161, 0.6);
}
ul.pagination { margin: 0 0 0 10px; padding: 0px; }
.table-striped thead > tr > td.top-row, thead > tr > td.top-row, tfoot > tr > td.top-row { padding: 15px 10px 8px 10px; }
.pagination-bottom { display: inline-block; float: right; vertical-align: middle; }
.search-div { display: inline-block; float: left; vertical-align: middle; }
.form-control.search-input { width: 240px; margin-right: 10px; display: inline-block; height: 34px;}
.modal-header .modal-search { color: #231F20; vertical-align: middle; width: 400px; padding: 9px 8px; font-size: 20px; }
#searchModal .views-exposed-form .views-exposed-widget .form-submit { margin-top: 0px !important; }
/*.views-exposed-form .views-submit-button, */ .views-exposed-form .views-reset-button { margin-top: -28px; }
strong.text-info { color: #00584C; }
.search-result { transition: all 0.3s ease-in-out; padding: 5px 10px; }
.search-result.card:hover { background: #fff; }

.view-searchapisearch .views-submit-button { margin-top: -28px; }
.view-searchapisearch h2::before { content: "" !important; border-bottom: none !important; }
.view-searchapisearch h2 { margin: 10px 0 10px; padding-bottom: 0px; font-size: 20px; text-transform: none !important; }
.view-searchapisearch .links.inline { display: none; }

.node-teaser:hover { background: #fff; }
.node-teaser { padding: 5px 10px 10px 10px !important; }
.node-teaser h3 { margin-top: 5px; font-size: 24px; letter-spacing: 0px; }
.form-control.page-sizes { width: 75px; display: inline-block; text-align: right; }
.form-control.letterlist { width: 80px !important; display: inline-block; margin-right: 5px; vertical-align: middle; }
.paging-div { display: inline-block; float: right; vertical-align: middle; padding-top: 0px; text-align: right; }
.paging-nav { display: inline-block; vertical-align: middle; padding-top: 0px; margin: 0px; }
.paging-div button, .paging-div .dropdown { margin-left: 5px; }
.table-striped th { background-color: #eee; }
a.up-arrow-pg { font-size: 2em; vertical-align: top; }
.pagination > li > a, .pagination > li > span { padding: 4px 8px 6px; }
.pagination.bk-fwd > li > a { padding: 6px 6px 8px 6px; }
.pagination.bk-fwd > li > a.select-page  { padding: 0px;  }
.pagination.bk-fwd > li > a.select-page .input-sm { display: inline-block; width: 30px;  text-align: center; border-radius: 0px; padding: 3px 5px 1px; height: 27px; border: 0px; }
.pagination.bk-fwd > li > a.select-page-of  { height: 29px; padding: 4px 6px 4px 6px; }
/*datepicker*/
.uib-daypicker .text-center { padding: 0px; }

.elected-officials{
	h4{
		height:45px;
	}
}

.elected-contact {
	padding-left: 15px;
	padding-top: 10px;
	border-top: 5px #00584C solid;
	background-color: #e1ebea;
	width: 290px;
}

.elected-official-block {
	background: #E1EBEA;
	height: 100%;
	display: block;
	margin-bottom: 20px;
	text-align: center;
	padding: 5px;
	border-top: 7px solid #00584c;
}

@media print {
	* { page-break-after: auto !important; }
	html, body { height: auto !important; }
	.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
		float: left;
	}
	.col-sm-12, .col-md-12 {
		width: 100%;
	}
	.col-sm-10,  .col-md-10 {
		width: 83.33333333%;
	}
	.col-sm-9 {
		width: 75%;
	}
	.col-sm-8, .col-md-8 {
		width: 66.66666667%;
	}
	.col-sm-6 {
		width: 50%;
	}
	.col-sm-4, .col-md-4 {
		width: 33.33333333%;
	}
	.col-sm-3, .col-md-3 {
		width: 25%;
	}
	.col-sm-2, .col-md-2 {
		width: 16.66666667%;
	}
	#detailModal { overflow: visible !important; }
	.modal .modal-body { max-height: auto !important; }
	.nav.nav-tabs.primary, .top-row, .menu-wrapper, .action-links, .modal-header, tfoot, .breadcrumb, .alert, #department-elected-official { display: none !important; }

	td, th { padding: 3px !important; }
	tr, tr td { page-break-inside: avoid; }
	.printModalBody, #department-elected-official{
		display: none !important;
	}
	
	.printModalDiv {
		display: block !important;
	}
	.printModalDiv .noprint, .ue-tab-container, .si-toggle-container, #edit_jump_chosen, #dept-menu-toggle { display: none !important; }
	.modal-content { }
	.modal-dialog, .modal-content, .modal .modal-body  {
		margin: 0px;  position: absolute; width: 95% !important;
		left: 10px; top: 0;
		margin: 0; padding: 0;
		min-height: 95% !important; height: 95% !important;
		border: none;
		overflow: visible !important;
	}
	a:after{content:"" !important;}
	.modal-body { margin-top: 0px; }
	.banner {display: none;}
	orphans:4; widows:2;
}
.pre { white-space: pre-wrap; }
#job-description {
	h1, h2, h3, h4 { text-transform: none !important; color: #9f481b; }
	h1 { margin-bottom: 12px; }
	h2 { margin-top: 6px; margin-bottom: 10px; font-size: 24px; }
	h3 { margin: 10px 0; font-size: 20px; }
}


/* for APPSTAGING homepage groups */
.list-group-item.active, .list-group-item.active:focus, .list-group-item.active:hover {
	background-color: #00584C;
	border-color: #00584C;
}

/* HEADER */
.st-nav, .st-foot { background: #00584c; color: #fff; }
 .st-foot { 
	background-image: url(/sites/default/files/htm-footer.png);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 1200px;
 }
.st-nav { height: 101px; }
.st-nav-links a, .st-nav-links a:hover, .st-foot a, .st-foot a:hover, .st-foot2 a, .st-foot2 a:hover, a.st-logo, a:hover.st-logo {  color: #fff; }
.st-nav-links a, .st-foot a, .st-foot2 a, a.st-logo, a:hover.st-logo { text-decoration: none;  background: none; border: none !important; }

.st-nav-links { float: right; display: inline-block; margin: 20px 40px 0 0;  }
.st-nav-links a { font-size: 20px; display: inline-block; padding: 10px; margin: 10px; }
a.st-logo { padding: 20px 0px; width: 200px; text-align: center; float: left; background: #363533 !important; }
a.st-logo:hover img { opacity: .6; }
.st-nav-links a:hover { color: #e6e6e6; }

/* BANNER */
button { padding: 15px; font-size: 18px; font-weight: bold; color: #fff; border: none; }
button:hover { opacity: .75; }
.banner { height: 456px; background-repeat: no-repeat; background-size: cover; text-align: center; }
.banner.top, .carousel-inner .item.top {
	background-position: top;
}
.banner.bottom, .carousel-inner .item.bottom {
	background-position: bottom;
}
.banner.center, .carousel-inner .item.center {
	background-position: center;
}
.banner .banner-txt { font-size: 35px; font-weight: bold; padding-top: 200px; padding-bottom: 20px; color: rgba(255,255,255,.8); text-transform: uppercase; }
@keyframes homeDescend {
  0% {
        margin-top: -30px;
  }
  100% {
        margin-top: 0px;
  }
}
.banner-content .banner-txt { animation: .75s  ease-in-out homeDescend; }
@keyframes fadeIn {
  0% {
        opacity: .25;
  }
  100% {
        opacity: 1;
  }
}
.navbar a.st-logo2 img { animation: .75s  ease-in-out fadeIn !important; }
.banner-logo {
	padding-top: 180px;
	position: absolute;
	width: 100%;
	text-align: center;
}
.banner-logo img { max-height: 110px; }
.banner-info { position: absolute; z-index: 500; display: inline-block; padding: 3px 10px; font-size: 20px; margin-top: 97px;right:5px; color: #fff; }
.banner-info .fa { opacity: .5; }
.banner-info:hover .fa { opacity: 1; }
.carousel-inner { z-index: -1; }
.banner.carousel, .carousel-control, .carousel-control:hover, .carousel-control:focus, .carousel-control:hover, .carousel-control.right:hover, .carousel-control.right:focus { background: none !important; background-image: none !important; filter: none !important;  }
.carousel-control:hover, .carousel-control:focus, .carousel-control:hover, .carousel-control.right:hover, .carousel-control.right:focus  { outline: 1px dashed rgba(255,255,255,.4) !important; }
.chosen-container-single .chosen-search input[type="text"]:focus { outline: 1px !important; }
.banner-content { text-align: center; width: 100%; position: absolute; z-index: 1; }
.carousel { text-align: center; }
.banner, .carousel .container, .carousel .container .banner-content, .carousel .item  { height: 456px; }
body.not-front .banner, body.not-front .carousel .container, body.not-front .carousel .container .banner-content,body.not-front .carousel .item  { height: 390px; }
.carousel .container { position: absolute; left: auto; }
.carousel .container .banner-content { position: relative; }
.carousel .item {
	background-repeat: no-repeat;
	background-size: cover;
	text-align: center;
}
.carousel-indicators {
	bottom: 50px;
	left: auto;
	right: auto;
	z-index: 15;
	width: 100%;
	padding-left: 0;
	margin-left: 0;
	text-align: center;
}
.carousel-control { z-index: 111; top: 45px; padding-top: 180px; padding-bottom: 160px; bottom: auto; }
.carousel-fade .carousel-inner .item {
	transition-property: opacity;
}
.carousel-fade .carousel-inner .item,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
	opacity: 0;
}
.carousel-fade .carousel-inner .active,
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
	opacity: 1;
	-webkit-transition: opacity 2s ease-in-out;
	-o-transition: opacity 2s ease-in-out;
	transition: opacity 2s ease-in-out;
}
.carousel-fade .carousel-inner .next,
.carousel-fade .carousel-inner .prev,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
	left: 0;
	transform: translate3d(0, 0, 0);
}
.carousel-control {
	z-index: 2;
}


.banner-content { height: 456px; background-repeat: no-repeat; background-size: cover; text-align: center; }
body.not-front .banner-content {height:390px !important}
.banner-content .banner-txt { font-size: 35px; font-weight: bold; padding-top: 200px; padding-bottom: 20px; color: rgba(255,255,255,.8); text-transform: uppercase; text-shadow: 2px 2px 4px rgba(70, 70, 70, .8); line-height: 1.1em; letter-spacing: .4px;  }
.banner-content .banner-txt span { font-size: .6em; text-transform: none !important; font-weight: normal; } 
.banner-content .search { font-size: 25px; opacity: .6; }
.banner-content .search:hover { opacity: .8; }
.banner-content .search input[type='text'] { padding: 10px 15px; border: 2px solid #fff; background: rgba(255,255,255,.65); text-align: center; height: 59px; }
.banner-content .search button { font-size: 30px; border-top: 2px solid #fff; border-right: 2px solid #fff; border-bottom: 2px solid #fff; vertical-align: top; padding: 6px 12px 6.5px 12px; }
.banner-content .search input[type='text']::-webkit-input-placeholder { color: #333; letter-spacing: 1.5px; }
.banner-content .search input[type='text']::-moz-placeholder { color: #333; letter-spacing: 1.5px;  }
.banner-content .search input[type='text']:-ms-input-placeholder { color: #333; letter-spacing: 1.5px;  }
.banner-content .search input[type='text']:-moz-placeholder { color: #333; letter-spacing: 1.5px;  }
.modal-search input[type='text']::-webkit-input-placeholder { color: #333; letter-spacing: 1.5px; }
.modal-search input[type='text']::-moz-placeholder { color: #0B5E55; }
.modal-search input[type='text']:-ms-input-placeholder { color: #0B5E55; }
.modal-search input[type='text']:-moz-placeholder { color: #0B5E55; }
#edit-search-api-views-fulltext { max-width: 220px; }
.btn-default, .btn-default:hover { background-color: #00584C !important; color: #fff !important; border: none; }

.btn-default.bg-tan:hover {  background-color: #7a673c; }
.btn-default:active, .btn-default.active, .btn-default.active:active, .open > .btn-default.dropdown-toggle { color: #333 !important; }
.banner-content button { opacity: 50%; }
.banner-content button:hover { opacity: 100%; }

/* NAV */
.st-nav { background-image: url(/sites/all/themes/larimer/assets/images/st-nav-bg.png);  background-position: center center; text-transform: uppercase; }
nav { opacity: .93; }
.nav-container { padding-left: 0px; padding-right: 0px; height: 100px; background: #00584c; }
.nav-container.fixed { height: 60px; }
input, button, a.st-logo img, .nav-photo2 .inner a, .banner .search, .banner-content .search { transition: all 0.3s ease-in-out; }
.navbar-header, .navbar-nav { }
.navbar-collapse { padding: 0px;  }
.navbar, button, .btn { border-radius: 0px; }
.nav > li > a { padding: 10px; }
.nav > li > a.dropdown-toggle .caret { display: none; }
.navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a:hover, navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a { color: #fff; }
.navbar-default .navbar-nav > li > a:focus, navbar-default .navbar-nav > li > a:focus { color: #F0E7D6 !important; }

.st-nav-links2 a.outline:hover, .st-nav-links2 a.outline:focus { background: #3A3937 !important; }
.st-nav-links2 a { border: none; }
.st-nav-links2 a.outline, .btn.outline { border: 1px solid rgba(255, 255, 255, .25) !important; }
.my-account-label { display: none; }
.dropdown-toggle.my-account {
    padding-left: 15px !important;
    padding-right: 13px !important;
}
a.outline.srch { padding-right: 10px; padding-left: 12px; }
a.my-account { padding-right: 10px !important; padding-left: 15px; }
.st-nav-links2 a.outline.srch { font-size: 20px; }
#bs-example-navbar-collapse-1 { text-align: left; }
.st-logo-mobile { display: none; }
.st-nav-links2 { float: right; margin: 20px 25px 0 0;  }
.st-nav-links2 a { font-size: 18px; display: inline-block; padding: 10px; margin: 10px 5px; }
.st-nav-links2 a:hover { color: #F0E7D6 !important; opacity: 1 !important; }
.open > .dropdown-menu {
	background: #00584C;
}
.dropdown-menu {
	font-size: 14px;
	text-align: left;
	list-style: none;
	position: absolute;
	top: 100%;
	left: 5px;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 160px;
	padding: 0px;
	margin: 0px;
	font-size: 14px;
	text-align: left;
	list-style: none;
	background-color: #fff;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	border: 1px solid #ccc;
	border: 1px solid rgba(0,0,0,.15);
	border-radius: 0px;
	-webkit-box-shadow: 6px 6px 12px rgba(0,0,0,.25);
	box-shadow: 6px 6px 12px rgba(0,0,0,.25);
	text-transform: none !important;
}
.dropdown-menu a { color: #fff !important; }
.navbar-nav > li > .dropdown-menu {
	border-radius: 0px;
}
.dropdown-menu > li > a, .dropdown-menu > li > a:focus {
	display: block;
	padding: 3px 10px;
	clear: both;
	font-size: 17px;
	font-weight: bold;
	line-height: 1.42857143;
	color: #fff;
	white-space: nowrap;
	margin: 0px;
}
.dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover, .dropdown-menu > .active > a, .dropdown-menu > .active > a:hover {
	color: #F0E7D6;
	text-decoration: none;
	background-color: #3A3937 !important;
}
.dropdown-menu .divider {
	height: 1px;
	margin: 0px; /* 5px 0 */
	overflow: hidden;
	background-color: #3A3937;
}
.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:focus, .navbar-default .navbar-nav > .open > a:hover {
	color: #F0E7D6;
	background-color: #3A3937;
}
.navbar { margin: 0px; border: 0px; }
a.st-logo { float: none; position: absolute; top: 0px;  }

.icon-links-mobile { display: none; }

/* STICKY HEADER */
.st-nav { position: fixed; width: 100%;  z-index: 999;
	-webkit-box-shadow: 6px 6px 12px rgba(0,0,0,.25);
	box-shadow: 6px 6px 12px rgba(0,0,0,.25);
}
.st-nav2 { border-bottom: 4px solid #fff; }

/* FOOTER */
.st-foot h3 { margin: 0 0 6px 0; font-size: 18px; color: #dbc69b; text-transform: uppercase; }
.st-foot { padding: 20px 0 40px; text-align: left; }
.st-foot ul { list-style-type: none; margin-left: -40px; }
.st-foot2 a, .st-foot2 a:hover { padding: 20px 0 12px; background: #231f20 !important; display: block; text-align: center; }
.st-foot .col-sm-3 { padding-top: 20px; }
.st-foot .col-sm-3 .col-sm-5 { padding: 0px !important; }
.st-foot .foot-spacer { padding: 10px 0; }
.st-foot p { margin: 0px; padding: 0px; }
a.si2, a:hover.si2, a:visited.si2 { display: inline-block; margin-right: 10px; font-size: 1.8em; margin-bottom: 6px; }
a.si2, .st-foot2 a img { transition: all 0.3s ease-in-out; }
a:hover.si2, .st-foot2 a:hover img { opacity: 0.6; }
.st-foot a:hover, .st-foot a.under { text-decoration: underline !important; }
.st-foot a.under:hover { text-decoration: none !important; }
.st-foot a.btn { background-color: #00584C; margin-bottom: 5px; }
.st-foot h3 a { color: #dbc69b !important; }
.flag-status { color: #fff; }
.flag-status .flag-icon {
    vertical-align: middle;
    margin-right: 10px;
    width: 30px;
    height: auto;
}
.flag-icon2 { margin-left: 10px; }
.st-foot .popover h3 {
    color: #9f481b !important; text-transform: none !important;
}

.field-name-spotlight-contact-details-2-labe { }
.field-name-field-contact-block .field-label { margin-bottom: -10px; }
/* feedbackbutton */
a#webfeedback{
	z-index:300;
	display:block;
	font-family: 'Montserrat',sans-serif;
	vertical-align:top;
	position:fixed;
	top: 35%;
	left:-78px !important;
	font-size: 0.8em;
	width: 179px;
	line-height:1.5em;
	transform:rotate(7deg);
	-ms-transform:rotate(270deg); /* IE 9 */
	-moz-transform:rotate(270deg); /* Firefox */
	-webkit-transform:rotate(270deg); /* Safari and Chrome */
	-o-transform:rotate(270deg); /* Opera */
	text-align:center;
	cursor:pointer;
	border: 1px solid #fff !important;
	padding: 4px 8px;
	font-variant:small-caps;
	color: #fff;
	outline: 0;
	letter-spacing: 1.5px;
	/*box-shadow*/
	box-shadow: 0 1px 4px rgba(0,0,0,.4), inset 0 1px 0 rgba(255,255,255,.4), inset 0 -1px 1px rgba(255,255,255,.4);
	/*linear-gradient*/
	background-color: #005569;
}
a#webfeedback:hover, a#webfeedback:focus {
	left:-75px !important;
	-webkit-transition: all 0.1s ease-out;
	-moz-transition: all 0.1s ease-out;
	-o-transition: all ease-out;
	transition: all 0.1s ease-out;
	background-color: #555;
	box-shadow: 0 1px 4px rgba(0,0,0,.6), inset 0 1px 0 rgba(255,255,255,.6), inset 0 -1px 1px rgba(255,255,255,.6);
	text-decoration: none !important;
}
input:focus, select:focus { outline: 1px; }
#edit-title, #edit-field-countywide-value { width: 200px; }

/* PAGE NAV STYLES */
.nav-photo, .nav-photo2, .nav-icon, .nav-btn, .nav-btn-desc, .pullout-photo { margin: 30px 0 0 0; }
.nav-photo a { display: block; margin-bottom: 10px; padding: 8px 0 20px; text-align: center;
	background: #7a673c; color: #fff;
	font-size: 1.2em; font-weight: bold;
	min-height: 80px;
}

.nav-photo a img { width: 100%; display: block; padding-bottom: 20px; }
.nav-photo a:hover .fa { color: #545552; }
.nav-photo div:nth-of-type(2) a, .nav-photo div:nth-of-type(6) a, .nav-photo div:nth-of-type(10) a, .nav-photo div:nth-of-type(16) a { background: #005569 !important; }
.nav-photo div:nth-of-type(3) a, .nav-photo div:nth-of-type(7) a, .nav-photo div:nth-of-type(11) a, .nav-photo div:nth-of-type(17) a { background: #9f481b !important; }
.nav-photo div:nth-of-type(4) a, .nav-photo div:nth-of-type(8) a, .nav-photo div:nth-of-type(12) a, .nav-photo div:nth-of-type(18) a { background: #585453 !important; }

.nav-photo2 ul { margin: 0px 0 0 -40px; list-style: none; text-align: center; }
.main-content .nav-photo2 ul li { line-height: 1.3em; }
.nav-photo2 .inner { background: #7a673c; margin-bottom: 10px; height: 100%; } 
.nav-photo2 .inner div { padding: 0 20px 10px 20px; color: #fff; text-align: center; }
.main-content .nav-photo2 .inner div p { padding: 0px; margin: 0px; }
.nav-photo2 a { color: #fff; }
.nav-photo2 a.top-half { display: block; margin-bottom: 0px; padding: 0px 0 0px; text-align: center;
	color: #fff; border-top: 6px solid #7a673c; background: #f6f0e4;
	letter-spacing: .25px !important;
	min-height: 80px;
}
.nav-photo2 a.top-half h3 { display: block; color: #f0e7d6; background: #7a673c; padding: 10px; margin-top: 0px; }
.nav-photo2 a img { display: block; margin: 0px auto; }
.nav-photo2 a:hover .fa { color: #545552; }
.nav-photo2 a:hover { text-decoration: underline !important; }
.nav-photo2 a.top-half:hover { text-decoration: none !important; }
.nav-photo2 a.top-half:hover h3 { color: #fff !important; }
.nav-photo2 div:nth-of-type(2) a.top-half h3, .nav-photo2 div:nth-of-type(6) a.top-half h3, .nav-photo2 div:nth-of-type(12) a.top-half h3, .nav-photo2 div:nth-of-type(11) a.top-half h3,
.nav-photo2 div:nth-of-type(2) .inner, .nav-photo2 div:nth-of-type(6) .inner, .nav-photo2 div:nth-of-type(12) .inner, .nav-photo2 div:nth-of-type(11) .inner { background: #005569 !important; }
.nav-photo2 div:nth-of-type(3) a.top-half h3, .nav-photo2 div:nth-of-type(7) a.top-half h3, .nav-photo2 div:nth-of-type(11) a.top-half h3, .nav-photo2 div:nth-of-type(12) a.top-half h3,
.nav-photo2 div:nth-of-type(3) .inner, .nav-photo2 div:nth-of-type(7) .inner, .nav-photo2 div:nth-of-type(11) .inner, .nav-photo2 div:nth-of-type(12) .inner  { background: #9f481b; }
.nav-photo2 div:nth-of-type(4) a.top-half h3, .nav-photo2 div:nth-of-type(8) a.top-half h3, .nav-photo2 div:nth-of-type(12) a.top-half h3, .nav-photo2 div:nth-of-type(12) a.top-half h3,
.nav-photo2 div:nth-of-type(4) .inner, .nav-photo2 div:nth-of-type(8) .inner, .nav-photo2 div:nth-of-type(12) .inner, .nav-photo2 div:nth-of-type(12) .inner  { background: #585453 !important;}
.nav-photo2 div:nth-of-type(2) a.top-half, .nav-photo2 div:nth-of-type(2) a.top-half, .nav-photo2 div:nth-of-type(6) a.top-half, .nav-photo2 div:nth-of-type(10) a.top-half, .nav-photo2 div:nth-of-type(11) a.top-half { border-color: #005569 !important; background: #e0eaec;}
.nav-photo2 div:nth-of-type(3) a.top-half, .nav-photo2 div:nth-of-type(7) a.top-half, .nav-photo2 div:nth-of-type(11) a.top-half { border-color: #9f481b; background: #f1e6e0; }
.nav-photo2 div:nth-of-type(4) a.top-half, .nav-photo2 div:nth-of-type(8) a.top-half, .nav-photo2 div:nth-of-type(12) a.top-half, .nav-photo2 div:nth-of-type(12) a.top-half { border-color: #585453; background: #E6E6E6; }
.nav-photo2 a:hover.top-half, .nav-photo2 a:hover.top-half { color: #fff !important; }

.nav-photo2.nav-photo-gray a:hover.top-half {background: #c1d2d5}
.nav-photo2.nav-photo-gray a.top-half h3 { background: #333; }
.nav-photo2.nav-photo-gray a:hover.top-half h3 {color:#7a673c}
.nav-photo2.nav-photo-gray a img {background: transparent; }
/*.nav-photo2.nav-photo-gray a:hover img, */
.nav-photo2.nav-photo-gray div a.top-half:hover {background: #f0e7d6; }
.nav-photo2.nav-photo-gray div a.top-half h3, .nav-photo2.nav-photo-gray div .inner { background: #333 !important; font-size:1.1em; height: 100%; }
.nav-photo2.nav-photo-gray div a.top-half h3 { margin-bottom: 0px; }
.nav-photo2.nav-photo-gray div a.top-half { border-color: #ddd !important; background: #ddd;}
.nav-photo2.nav-photo-gray div a.top-half:hover {border-color: #7a673c !important;}

.nav-btn a, .nav-btn-desc a { display: block; padding: 10px;
	background: #f4e9e3; color: #9f481b; border-top: 4px solid #9f481b;
	font-size: 1.2em; font-weight: bold;
	height: 100%;
}
.nav-btn-desc a span { display: block; font-size: 15px; color: #231F20; font-weight: normal !important; text-transform: none; padding-top: 5px; }

.nav-btn-desc a:hover span { text-decoration: none !important; }
.nav-btn a .fa, .nav-btn-desc a .fa { color: #9f481b; display: inline-block; float: right; font-size: 1.2em; padding: 0px 0px 10px 10px; vertical-align: top; }
.nav-btn a:hover .fa, .nav-btn-desc a:hover .fa { color: #545552; }

.nav-icon a { display: block; background: #e0eaec; color: #545552; font-size: 1.2em; font-weight: bold; }
.nav-icon a .fa { color: #e0eaec; display: inline-block; font-size: 2.5em; padding: 15px 0px 15px 15px; width: 85px; background: #005569; margin-right: 25px; vertical-align: middle; float: left; }
.nav-icon a .fa.img { padding: 5px; transition: all 0.3s ease-in-out; }
.nav-icon a:hover .fa.img { padding-top: 2px !important; }
.nav-icon div span {
    display: table-cell;
    vertical-align: middle;
    height: 78px;
	color: #005569;
}

.nav-icon a:hover .fa { color: #fff; }
.dropdown-menu .nolink {
    padding: 10px 20px;
    font-size: 1.1em;
    color: #ccc;
}

.alert-page { background: #e1ebea; display: block; margin-bottom: 20px; min-height: 84px; position:relative; text-decoration: none !important; }
.alert-page ul, .alert-page ol { margin-top: 6; margin-bottom: 0px; }
.main-content .alert-page p { padding: 0px; margin: 0px; }
.alert-page span.h3 { margin: 0 0 8px; display: block; font-weight: bold; }
.alert-page a.linked span.h3 { text-decoration: underline !important; }
#main .alert-page a:hover { text-decoration: none !important; }
.alert-page span.div { padding: 13px 15px 12px 0; margin-left: 120px; vertical-align: top; display: block; }
.alert-page .fa.big-icon {height:100%; background: #00584c; color: #e1ebea; display: inline-block; position: absolute; 	   font-size: 4em;  padding: 10px 0px 10px 13px; width: 100px !important; margin-right: 25px; vertical-align: middle; text-align: center; }
#main .alert-page.tan a, #main .alert-page.tan a:hover { color: #585453 !important; }
#main .alert-page.blue a, #main .alert-page.blue a:hover { color: #005569 !important; }
#main .alert-page.sienna a, #main .alert-page.sienna a:hover { color: #9f481b !important; }
#main .alert-page.gray a, #main .alert-page.gray a:hover { color: #585453 !important; }
#main .alert-page.brown a, #main .alert-page.brown a:hover { color: #585453 !important; }
.alert-page.tan, .alert-page.tan a:hover { background: #f0e7d6; color: #585453; }
.alert-page.tan .fa.big-icon { color: #f0e7d6; background: #585453; }
.alert-page.blue, .alert-page.blue a:hover { background: #E0EAEC; color: #005569; }
.alert-page.blue .fa.big-icon { color: #E0EAEC; background: #005569; }
.alert-page.sienna, .alert-page.sienna a:hover { background: #f4e9e3; color: #9f481b; }
.alert-page.sienna .fa.big-icon { color: #f4e9e3; background: #9f481b; }
.alert-page.gray, .alert-page.gray a:hover { background: #efeeee; color: #585453; }
.alert-page.gray .fa.big-icon { color: #efeeee; background: #585453; }
.alert-page.brown, .alert-page.brown a:hover { background: #efeeee; color: #585453; }
.alert-page.brown .fa.big-icon { color: #efeeee; background: #585453; }
.alert-page:hover .fa.big-icon { color: #fff !important; }

.alert-site { padding: 5px; background: #9f481b !important; text-align: center; font-size: 1.15em; font-family: 'PT Sans Narrow',sans-serif; color:#fff !important;font-weight:bold }
.alert-site div, .alert-site p { display: inline-block; text-align: center; padding: 0px; margin: 0px; }
.alert-closure { background: #c5b68d !important; color: #333 !important; }
.alert-closure .close { color: #666 !important; }
.alert-emergency a {color:#faFCB8 !important;}
.alert-site a, .alert-closure a { text-decoration: underline; }
.alert-site.collapse.in.fixed { display: none; }

.pullout-photo { border-top: 6px solid #c8b58d; background: #f0e7d6; margin: 30px 0px 0 0px !important;}
.pullout-photo .img, .pullout-profile .img { padding: 0px; background-size: cover; background-position: center; min-height: 240px; }
.pullout-photo img, .pullout-profile img { width: 100%; height: auto; display: block;  }
.pullout-photo .txt, .pullout-profile .txt { padding: 0 30px 0 30px; }
.pullout-photo h3, .pullout-profile h3 {  }
.pullout-photo a:hover { text-decoration: none !important; }
.pullout-photo a.btn:hover { text-decoration: underline !important; }
.contact-p strong { display: inline-block; width: 110px; padding-right: 5px; text-align: right !important; }

.fQ { font-size: 1.25em; font-weight: bold; }
/*.main-content .fA p { padding: 0 0 15px 0;}*/
.fA a { text-decoration: underline !important; }
.fA a:hover { text-decoration: none !important; }
.fA a.btn { text-decoration: none !important; }
.fA a.btn:hover { text-decoration: underline !important; }
.faq { counter-reset: my-counter; }
.faq dt:before { content: counter(my-counter); counter-increment: my-counter; }
.faqs .collapse ul, .faqs .collapse ol { margin-bottom: 15px; }
.expanding-horizons.faq-expand { margin-top: -44px; padding-bottom: 20px; }

/* accordion */
.accordions .field-collection-view .field-collection-view-links { margin-top: -35px !important; }
.ui-accordion .ui-accordion-content { margin-top: 0px !important; }
.ui-widget { font-family: 'Montserrat', sans-serif; font-size: 1em; }
.ui-accordion-header { font-weight: bold; font-size: 1.05em; }
.ui-widget-content {  border-color: #ddd !important;  }
.ui-tooltip { background: black; font-size: 12px; display: inline-block; max-width: 200px; padding: 3px 8px;
	color: #fff; z-index: 99; border: none; left: -50px;
}
.ui-widget-content a { color: #00584C; }
.ui-widget-content a.btn { color: #fff; }

.accordion-header { background: #e6e6e6; margin-top: 2px; padding: .5em .5em .5em .7em; display: block; } 
.faqa .accordion-content { border: 1px solid #e6e6e6 !important; padding: 1em 2.2em; }
.faqa .fQ { font-size: 1em; }
.faqa a.fQ  { text-decoration: none !important; }
.faqa a.fQ:hover { text-decoration: underline !important; background: #F0E7D6; }
.faqa a.fQ .fa { padding: 0 5px; }
.accordion-header.fQ.collapsed .fa-caret-down::before { content: "\f0da"; }
.main-content .fQ p { display: inline-block; vertical-align: top; margin-top: -2px; }

.uib-daypicker th { padding: 0px !important; }
li.uib-button-bar { padding: 0px; margin-top: -20px; }
td.uib-day button { padding: 2px 8px; }
.arrested-yes { position: absolute; z-index: 10; width: 92%; background-image: url(/sites/default/files/bg-arrested.png); background-size: contain; background-repeat: no-repeat; transition: all 0.3s ease-in-out; }
.group-photos .arrested-yes { height: 100%;}
.view-most-wanted a{ position:relative}
.view-most-wanted .arrested-yes { height:100px !important; left:0; transition: all 0.3s ease-in-out;}
.arrested-yes:hover  { opacity: 0.3; }
.highlight { background: #fef498; padding: 2px; }
.col-sm-8.group-right .label-inline, .field-label-inline .field-label { width: 191px; }

/* PARK_AREA */
.park-thumb { position: absolute; margin-top: 3px; }
.park-desc { padding: 6px 10px 5px 110px; min-height: 103px; border-top: 3px solid #00584C; background: #E1EBEA; margin-bottom: 10px; }
#main .park-desc a:link { text-decoration: none; }
.jquery-once-2-processed
.park-features.col { margin-top: 0px; margin-bottom: 0px; }
.map-popup-title { font-size: 1.1em; }
.park-features.col strong { font-size: 1.2em; }
.park-features .park-icon { background-image: url(/sites/all/themes/larimer/assets/images/park-icons-24.png?20171006); background-repeat: no-repeat; margin-right: 5px; display: inline-block; width: 24px; height: 24px; vertical-align: middle; }
.park-features .park-icon-div { display: inline-block; margin: 0px 3px 10px 0; border: none; color: #00584C; padding: 5px;  font-size: .9em; }
.park-features .park-icon-div:hover { background: #ddd; }
.btn a, div.btn { margin-bottom: 6px; }
.field-operations ul.field-collection-view-links { list-style-type: none; margin: 0 0 0 -35px; }
.field-operations ul.field-collection-view-links li { display: inline-block; }
.field-operations ul.field-collection-view-links li.edit.first { margin-right: 10px; }
.action-links a, .field-operations ul.field-collection-view-links li a { display: inline-block; padding: 1px 5px; background: #00584C; text-decoration: none !important; color: #fff !important; font-size: .85em; text-transform: uppercase; }
.field-operations ul.field-collection-view-links li.delete a { background: #9f481b; }
.field-operations ul.field-collection-view-links li.edit a { background: #005569; }
.field-operations ul.field-collection-view-links li a:before, .action-links-field-collection-add a:before  {
	font-family: FontAwesome; padding-right: 5px;
}
.field-operations ul.field-collection-view-links li.delete a:before {
	content: "\f014";
}
.field-operations ul.field-collection-view-links li.edit a:before {
	content: "\f040";
}
.action-links-field-collection-add a:before {
	content: "\f0fe";
}

.resource-image { max-height: 200px; width: auto !important; }
#page-header .action-links {
    margin-top: -60px;
    position: absolute;
    right: 0px;
}
.webform-submit.button-primary.form-submit.btn.btn-default.btn-primary {
    margin-top: 10px !important; 
}
.not-logged-in .webform-submit.button-primary.form-submit.btn.btn-default.btn-primary {
    margin-top: 100px !important; 
}
.accordion-header { font-weight: bold; }
.accordion-header a { text-decoration: none !important;}
.accordion-header a:hover { text-decoration: underline !important; }

.acceptingapps::before { padding-right: 5px; }
#edit_jump_chosen, .view-id-bboard_offices.view { border-top: 4px solid #9F481B !important; }
.view-id-bboard_offices.view { float: right !important;  margin: 0 0 20px 20px; }
.view-id-bboard_offices.view select { width: 100%; padding: 8px 10px; }
.chosen-container-single .chosen-single { font-size: 1em !important; }
.form-item-jump label, .article-text .btn span, table caption, 
.si2 span, .outline.srch span, .carousel-control span, .icon-links-mobile span, .search button span, .modal-header .btn span { position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0; } // same as bootstrap's .sr-only
.node-type-event .field-name-field-park-location .adr { margin-top: -12px; }  
.node-type-event .rrssb {
    margin-top: 0px;
}
.node-type-event .rrssb .rrssb-buttons {
    margin: 0.15em 0 0.15em 180px !important;
	padding-left: 0px !important; 
}	
.node-type-event .rrssb .rrssb-prefix {
    padding-right: 0em !important;
    font-size: 16px !important;
}
	 
 
@media only speech { 
	.form-item-jump label, .article-text .btn span, table caption, .si2 span, .outline.srch span, .carousel-control span, .icon-links-mobile span, .search button span, .modal-header .btn span { display: inline-block; }
}


@media only screen and (min-width: 776px) {
	#edit_jump_chosen { width: 300px !important; }
	#ctools-jump-menu { width: 300px !important; float: right; margin-bottom: -40px; }
	/* .node-type-park-area */
}
#edit-field-park-features-value-wrapper .views-widget, #edit-field-park-features-value-wrapper label { display: inline-block; }
#edit-field-park-features-value-wrapper input[type='text'] { font-size: 14px; }

.no-commas { display: inline-block; padding-top: 3px; font-size: 1px; letter-spacing: 3px; }
.park-features .no-commas .park-icon, .park-features.no-commas .park-icon { margin-right: -1px; }
.park-features {
	.park-icon.Biking 		,.park-icon.biking 		{ background-position: 0 0; }
	.park-icon.Boating		,.park-icon.boating		{ background-position: -24px  0; }
	.park-icon.Camping		,.park-icon.camping		{ background-position: -48px  0; }
	.park-icon.Fishing		,.park-icon.fishing		{ background-position: -72px  0; }
	.park-icon.Hiking		,.park-icon.hiking		{ background-position: -96px  0; }
	.park-icon.Horseback 	,.park-icon.horse	 	{ background-position: -120px 0; }
	.park-icon.Marina 		,.park-icon.marina 		{ background-position: -144px 0; }
	.park-icon.Dogs 		,.park-icon.dogs 		{ background-position: -168px 0; }
	.park-icon.Permit 		,.park-icon.permit 		{ background-position: -192px 0; }
	.park-icon.Picnic 		,.park-icon.picnic 		{ background-position: -216px 0; }
	.park-icon.Restroom 	,.park-icon.restroom 	{ background-position: -240px 0; }
	.park-icon.Swimming 	,.park-icon.swimming 	{ background-position: -264px 0; }
	.park-icon.Hunting 	,.park-icon.hunting 	{ background-position: -288px 0; }
	.park-icon.Webcam  	,.park-icon.webcam  	{ background-position: -312px 0; }
	.park-icon.dogsAllowed ,.park-icon.dogsAllowed { background-position: -336px 0; }
	.park-icon.Drinking  	,.park-icon.drinking  	{ background-position: -360px 0; }
	.park-icon.Trails  	,.park-icon.trails  	{ background-position: -384px 0; }
	.park-icon.Accessible  	,.park-icon.accessible  	{ background-position: -408px 0; }
	.park-icon.Reservable  	,.park-icon.reservable  	{ background-position: -432px 0; }
	.park-icon.Diving  	,.park-icon.diving  	{ background-position: -456px 0; }
}
.park-counter {
	float: right;
	display: inline-block;
	background: #00584C;
	color: #fff;
	padding: 0px 6px;
	margin: -6px -10px 5px 10px;
	font-size: .85em;
}
.view-openlands-acquisitions td:nth-child(1), .view-openlands-acquisitions td:nth-child(3) { width: 40%; }
.view-openlands-acquisitions td:nth-child(2) { text-align: right; }
.view-openlands-acquisitions td:nth-child(3) { color: #fff; }
.view-openlands-acquisitions td:nth-child(3) .park-icon { margin-right: 2px; }
.view-openlands-acquisitions td:nth-child(3) .not-open { color: #999; }
.node-open-spaces ul, .node-open-spaces ol { margin-bottom: 0px; }
.view-openlands-acquisitions caption { font-weight: bold; color: #9f481b; padding-top: 0px; }
.ctools-auto-submit-full-form { margin-bottom: 20px; }
.weather .day .hourly { padding-top: 15px; }

.views-exposed-form .views-widget { display: inline-block; }
.year.mini-day-on { background: #F7B215; }
.location .fn { font-weight: bold; }

.ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br {
	border-bottom-right-radius: 0px;
}
.ui-corner-all, .ui-corner-bottom, .ui-corner-left, .ui-corner-bl {
	border-bottom-left-radius: 0px;
}
.ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr {
	border-top-right-radius: 0px;
}
.ui-corner-all, .ui-corner-top, .ui-corner-left, .ui-corner-tl {
	border-top-left-radius: 0px;
}
.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default
{
	border: 1px solid #e6e6e6;
	background: #e6e6e6;
	background-image: none;
}
.ui-state-default:hover, .ui-widget-content .ui-state-default:hover, .ui-widget-header .ui-state-default:hover
{
	border: 1px solid #F0E7D6;
	background: #F0E7D6;
	background-image: none;
}
.ui-state-default.ui-accordion-header-active
{
	border: 1px solid #E1EBEA;
	background: #E1EBEA;
	background-image: none;
}
h3.field-group-format-toggler  { font-weight: bold; }
h3.field-group-format-toggler a { text-decoration: none !important; }
/* stars */
._Jxg, ._Jxg span {
	background-size: 14px 13px;
	height: 13px;
	top: 1px;
	width: 69px;
}
._Jxg {
	background-image: url(/sites/all/themes/larimer/assets/images/star-gry.png);
}
._pxg {
	background-repeat: repeat-x;
	display: inline-block;
	overflow: hidden;
	position: relative;
}
._Jxg, ._Jxg span {
	background-size: 14px 13px;
	height: 13px;
	top: 1px;
	width: 69px;
}
._Jxg span {
	background-image: url(/sites/all/themes/larimer/assets/images/star.png);
}
._pxg span {
	background-repeat: repeat-x;
	display: block;
}
._pxg span {
	background-repeat: repeat-x;
	display: block;
}
.google-place-author { position: absolute; }
.google-place-author img {
	border: none;
	border-radius: 50%;
	height: 40px;
	width: 40px;
}
.gp-review { display: inline-block; padding-left: 50px; padding-top: 5px; font-size: .9em; }
.row.gallery img {max-width: 100%; margin-bottom: 5px; height:inherit}
.photo-enlarge { margin-top: 10px !important; padding: 5px 10px !important; background: #ddd; }
.slides img { width: 100%; }
.trail-links a { vertical-align: bottom; margin-right: 10px; }
.gdata-rating {
	padding: 5px 0 15px;
}
.gmap-popup h4 { font-size: 17px; margin: 0px; }
.map-popup-image { float: left; margin: 0 10px 0px 0; width: 200px; }
.map-popup-text  { float: left;  width: 180px; }
.embedded-map { width: 100%; height: 450px; border: 1px solid #ccc; }
.perp-pic img { height: auto !important; }
.perp-thm { font-size: .85em; margin-bottom: 10px; margin-top: 5px; text-transform: uppercase; }


/* COLOR CLASSES */
.brown { color: #585453 !important; }
.blue { color: #005569 !important; }
.red { color: #9f481b !important; }
.green { color: #00584C !important; }
.gray { color: #585453 !important; }
.bg-brown {  background-color: #585453; 
	.btn { background: #F0E7D6 !important; }
}
.bg-blue {  background-color: #005569; 
	.btn { background: #E0EAEC !important; }
}
.bg-tan {  background-color: #7a673c; 
	.btn { background: #F0E7D6 !important; }
}
.bg-red {  background-color: #9f481b; 
	.btn { background: #F4E9E3 !important; }
}
.bg-green {  background-color: #00584C; 
	.btn { background: #E1EBEA !important; }
}
.bg-gray {  background-color: #585453; 
	.btn { background: #EFEEEE !important; }
}
.bg-sienna {  background-color: #9f481b; 
	.btn { background: #F4E9E3 !important; }
}

.bg-lt-brown {  background-color: #F0E7D6; 
	.btn { background: #585453 !important; }
}
.bg-lt-blue {  background-color: #E0EAEC; 
	.btn { background: #005569 !important; }
}
.bg-lt-tan {  background-color: #F0E7D6; 
	.btn { background: #7a673c !important; }
}
.bg-lt-red {  background-color: #F4E9E3; 
	.btn { background: #9f481b !important; }
}
.bg-lt-green {  background-color: #E1EBEA; 
	.btn { background: #00584C !important; }
}
.bg-lt-gray {  background-color: #EFEEEE; 
	.btn { background: #585453 !important; }
}
.bg-lt-sienna {  background-color: #E7D3C8; 
	.btn { background: #9f481b !important; }
}

h1.brown:before, h2.brown:before, h3.brown:before { border-color: #585453; color: #585453 !important; }
h1.blue:before, h2.blue:before, h3.blue:before { border-color: #005569; color: #005569 !important;}
h1.red:before, h2.red:before, h3.red:before { border-color: #9f481b; color: #9f481b !important; }
h1.green:before, h2.green:before, h3.green:before { border-color: #00584C; color: #00584C !important; }
h1.gray:before, h2.gray:before, h3.gray:before { border-color: #585453; color: #585453 !important; }
.pullout-photo.div-brown { border-color: #585453; background-color: #f6f0e4 !important; }
.pullout-photo.div-blue { border-color: #005569; background-color: #e0eaec !important;}
.pullout-photo.div-red { border-color: #9f481b; background-color: #f1e6e0 !important; }
.pullout-photo.div-green { border-color: #00584C; background-color: #e1ebea !important; }
.pullout-photo.div-gray { border-color: #585453; background-color: #e6e6e6 !important; }
.pullout-photo strong {  }
.not-bold { font-weight: normal !important; }
.pullout-photo p { font-size: 1.1em; }
#newTabs .panel-body+h2, #newTabs .panel-body+h3, #newTabs .panel-body+h4 { margin-top: 0px; }

/* DATA POINTS */
.data  [class*='col-'] { border-right: 3px solid #fff; padding-top: 10px; padding-bottom: 10px; font-family: 'PT Sans Narrow',sans-serif; }
.data .row.inner  [class*='col-'] { border-right: 0px; }
.data-h2, .data h4 { font-weight: bold; }
.data-h2 { font-size: 1.3em; }
.data h4 { font-size: 33px; }
.data-moreinfo { float: right; }
.row.data { margin-left: 0px !important; margin-right: 0px !important; }
.data-content-above { margin-top: 10px; }
.bg-green, .bg-blue, .bg-red, .bg-brown, .bg-gray, .bg-tan, .bg-green a, .bg-blue a, .bg-red a, .bg-brown a, .bg-gray a, .bg-tan a, .bg-sienna a { color: #fff; }
.bg-lt-brown, .bg-lt-blue, .bg-lt-tan, .bg-lt-red, .bg-lt-green, .bg-lt-gray { border-top: 4px solid; }
.bg-lt-brown { border-color: #7a673c; color: #7a673c; }
.bg-lt-blue  { border-color: #005569; color: #005569; }
.bg-lt-tan   { border-color: #7a673c; color: #7a673c; }
.bg-lt-red   { border-color: #9f481b; color: #9f481b; }
.bg-lt-green { border-color: #00584C; color: #00584C; }
.bg-lt-gray  { border-color: #585453; color: #585453; }
.bg-lt-sienna  { border-color: #585453; color: #585453; }
.sparkline { float: left; height: 1em; margin: 0 0.5em; }
.sparkline .index { position: relative; float: left; height: 100%; margin-right: 1px; }
.sparkline .index .count { display: block; position: absolute; bottom: 0; left: 0;
    width: 100%; height: 0; overflow: hidden; text-indent: -9999px; background: #666;
}

.bg-lt-brown   .sparkline .index .count { background: #7a673c; }
.bg-lt-blue    .sparkline .index .count { background: #005569; }
.bg-lt-tan     .sparkline .index .count { background: #7a673c; }
.bg-lt-red     .sparkline .index .count { background: #9f481b; }
.bg-lt-green   .sparkline .index .count { background: #00584C; }
.bg-lt-gray    .sparkline .index .count { background: #585453; }
.bg-lt-sienna  .sparkline .index .count { background: #585453; }
.sparkline.dark .index .count {
    background: #fff !important;
}
.accordion-content .row {
    margin-left: 0px; margin-right: 0px;
}

/* NEAR YOU */
.near-you ul { list-style: none; margin: 0px !important; padding: 0px; }
.near-you li { background: #e4edec; font-family: 'PT Sans Narrow', sans-serif; }
.near-you li a { display: block; background: #e4edec; color: #545552; text-transform: uppercase; font-size: 1.4em; margin-bottom: 10px; text-decoration: none !important; position: relative;overflow: hidden !important;}
.near-you li a:hover strong { text-decoration: underline !important; }
.near-you li a .fa { color: #9f481b; position: absolute; font-size: 2.7em; padding: 5px 0px 5px 15px; width: 70px; background: #cbdedc; margin-right: 15px; vertical-align: middle; transition: all 0.3s ease-in-out; height:100%;}
.near-you li a:hover .fa { background: #e4edec; }
.near-you li:nth-of-type(2n) a .fa { color: #005569; }
.near-you li:nth-of-type(3n) a .fa { color: #9e9271; }
.near-you li:nth-of-type(4n) a .fa { color: #00584C; }
.near-you li:nth-of-type(5n) a .fa { color: #585453; }
.near-you li a div { font-size: 14px; }
.near-you li a span {
	font-weight: bold;
	padding: 8px 8px 8px 90px;
	min-height: 72px;
	display: block;
}
.near-you li a strong { font-weight: bold; display: block; padding-bottom: 5px; }
.near-you .col { padding: 0px; }
.near-you .col.col-sm-4 { padding-right: 10px; }
#map { border: 1px solid #ccc; }

/* IWANT STYLES */
.iwant { font-size: 19px; color: #fff; }
.iwant1, .iwant2 { text-transform: uppercase; }
.iwant-h1 { width: 270px; color: #005569; text-transform: uppercase; /* so button can right float */ }
.view-county-news h1, .view-display-id-upcoming_events h1 {  text-transform: uppercase; }
.iwant ul, .iwantto .iwant ul {
	list-style: none;
	margin: 0px !important;
	padding: 0px;
}
@media only screen { 
	.iwant ul, .iwantto .iwant ul { height: 238px; }
}

/* chrome only */
@media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {
	.main-content .iwant2 ul, .main-content .iwant3 ul, .main-content .iwant2 ul, .main-content .iwant3 ul {
		height: 236px;
	}
} 
.iwant .iwant1 li, .iwantto .iwant .iwant1 li { background: #448291; text-align: center; font-weight: bold; padding: 15px 10px; margin-bottom:  10px !important; margin-right: 15px; transition: background 0.3s ease-in-out;  }
.iwant .iwant1 li.active, .iwantto .iwant .iwant1 li.active { background: #005569; margin-right: 0px; padding-right: 25px; }
.iwant1 { float: left; width: 23% }
.iwant2 { float: left; width: 27%; background-color: #005569; padding: 15px 15px 11px 25px; font-weight: bold; }
.bboard .iwant2 { padding-top: 10px !important; }
.bg-icon { height: 160px; width: 160px; display: none; opacity: 0.3;
	position: absolute; margin: 70px 0 0 0px;  z-index: 2;
	background-repeat: no-repeat; background-image: url(/sites/all/themes/larimer/assets/images/home-iwant.png);
	 transition: all 0.3s ease-in-out;
}
.bg-icon.active { display: block; }
.bg-icon3 { background-position: 0 0; }
.bg-icon2 { background-position: -480px 0; }
.bg-icon1 { background-position: -320px 0; }
.bg-icon0 { background-position: -160px 0; }

.bboard .bg-icon3 { background-position: 0 0; }
.bboard .bg-icon2 { background-position: -640px 0; }
.bboard .bg-icon1 { background-position: -800px 0; }
.bboard .bg-icon0 { background-position: -320px 0; } 

.iwant2 li, .iwant3 ul.rootlist > li { display: none; }
.iwant2 li.active, .iwant3 ul.rootlist > li.active { display: block; }
.rootlist { height: 235px; overflow-y: auto; }
.iwant3 { float: left; width: 50%; background: #005569; padding: 15px 0px 11px 50px; font-size: 16px; }
.iwant3 a, .iwant3 a:hover { color: #fff; border: none; background: none; font-size: 1.3em; line-height: 1.4em; transition: all 0.3s ease-in-out; }
.iwant2, .iwant3 { padding-bottom: 5px !important; }
.iwant3 a:hover { text-decoration: underline !important; }
.iwantto { height: 100%; display: none; position: fixed; z-index: 1000; top: 100px; width: 100%; background-color:rgba(0, 0, 0, 0.5); }
.iwantto .container { text-align: left; background: #fff; padding: 5px 10px; }
.iwantto.fixed { top: 60px; }
.main-content .iwant2, .main-content .iwant3 { padding-bottom: 11px !important; }

.logged-in .iwantto { top: 30px; }
.logged-in .iwantto.fixed { top: 90px; }
.more { text-align: right; padding: 0 0 0px 0; text-transform: uppercase; font-weight: bold; font-size: 18px; }
.more a { color: #595552; }
#main .more a:hover { text-decoration: none !important; }
.view-content::after { clear: both; }
.view-content::before, .view-content::after { content: " "; display: table; }

.upper { text-transform: uppercase; }
.team h3 { margin-top: 0px !important; }

/* GOOGLE TRANSLATE IN FOOTER */
#google_translate_element {
	position: relative; width: 205px; padding-left:5px !important; overflow: hidden;
}
#google_translate_element span { border: none !important; margin-top: 5px; }
#google_translate_element span:before {
	font-family: FontAwesome;  content: "\f1a0";  position: absolute;  top: 5px;  left: -18px;  font-size:1.4em;  color: #f0f0f0;
}
.goog-te-gadget { color: #fff !important; }
#google_translate_element select { width: 240px; color: #fff !important; background-color: #00584C !important;  border: none; margin-left: -5px;  -webkit-appearance: none; font-size: 18px; }
#google_translate_element .skiptranslate { font-size: 0px; margin-bottom: 10px; }
#google_translate_element .skiptranslate img, #google_translate_element .skiptranslate a { display: none; }
.row { margin-left: -15px; margin-right: -15px; }

#comments { border-top: 1px solid #ccc; }
.demo-label { background: #ccc; margin-left: -20px; margin-top: 25px; margin-bottom: 15px; }
.demo-label span { display: inline-block; background: #9f481b; padding: 5px 10px; color: #fff; margin-right: 10px; border-right: 1px solid #F4E9E3; }

.sharing-links a, .sharing-links a:hover {
	background: none;
	underline: none;
	text-decoration: none !important;
	border: none;
	font-size: 2em;
	padding-right: 2px;
}
.close {
	float: right;
	font-size: 24px;
	font-weight: bold;
	line-height: 1;
	color: #fff !important;
	text-shadow: none !important;
	opacity: .7 !important;
	filter: alpha(opacity=70) !important;
}
.close:hover {
	opacity: 1 !important;
	filter: alpha(opacity=100) !important;
}
.popover-content {
	color: #666 !important;
}

.no-banner #myCarousel, .no-banner .banner { display: none; }
.main-content.no-banner { padding-top: 120px; }
/*@media only screen and (max-width: 991px) {
	.main-content.no-banner { padding-top: 92px; }
}*/

/* BBOARD BLUE */

.bboard {
	.st-nav { background-image: url(/sites/all/themes/larimer/assets/images/st-nav-bg-bboard.png); }
	.nav-container, .st-foot, .open > .dropdown-menu, .modal-header, .btn-default, .btn-default:hover, #google_translate_element select, .btn-primary, body.maintenance-page .form-actions .btn:first-child, .btn, a.btn, .btn a { background: #005569 !important; }
}
.btn.btn-white {
    background: #fff !important;
    border: 1px solid #ccc !important;
    color: #00584C !important;
}
.btn.btn-white:hover {
    color: #00584C !important;
    background: #efefef !important;
    text-decoration: none !important;
}
.bboard .btn.active, .bboard a.btn.active, .bboard .btn a.active,
.bboard .btn.active, .bboard .btn.btn-white { background: #fff !important; border: 1px solid #005569 !important; box-shadow: none !important; color: #005569 !important; }
.bboard .btn.btn-white:hover { background: #efefef !important; }
.resource-image { max-height: 160px; }
.node-type-bboard-offices .location.vcard, .node-location .location.vcard, .field-name-field-mapped-location .location.vcard {
    margin-bottom: 15px !important;
}
.da-ask { border: 1px solid #ccc; background: #efefef; float: right; margin: 0 0 20px 20px; width: 360px; }
.da-ask img { width: 150px; margin-right: 15px; float: left; }
.da-ask p { margin: 10px !important; font-size: .95em !important; }
.admin-menu-toolbar-category:nth-of-type(n+10) { display: none; }
li:empty { display: none; }
hr { width: 100%; }
th.st-sort-ascent::after, th.st-sort-descent::after {
    float: right;
    font-family: FontAwesome;
	color: #9f481b;
	padding-left: 3px;
}
th.st-sort-ascent::after {
    content: "\f0de";
}
th.st-sort-descent::after {
    content: "\f0dd";
}
.gm-style .gm-style-iw-c { border-radius: 0px; }
blockquote, address {
    padding: 11px 22px;
    margin: 0 0 22px;
    font-size: 20px;
    border-left: 5px solid #eeeeee;
}

.node-unpublished::before {
    content: "-- UNPUBLISHED --";
    color: #9f481b;
    padding: 10px 0;
    font-weight: bold;
}
.node-type-event .node-park-area h2 { display: none; }
article.node.node-park-area.contextual-links-region.clearfix {
    display: inline-block; 
}
.media-element-container.media-default {
    display: inline-block !important;
}
a .file { text-decoration: underline; text-decoration: underline;}

/* INSIDE INFO ARCHIVE JUMP MENUS */
.node-type-bboard-news .view-bboard-news { display: none; }
div[about*="hires"] .view-display-id-block_ii_hires { display: block !important; }
div[about*="employee-recognition"] .view-display-id-block_ii_reco, div[about*="well-done"] .view-display-id-block_ii_reco { display: block !important; }
div[about*="letter-county-manager"] .view-display-id-block_ii_letter_mgr { display: block !important; }

/* SPANISH LANGUAGE FIXES */
html:lang(es) .rrssb .rrssb-text { margin-left: -400px; }	
html:lang(es) .nav-quick div a .fa { font-size: 1.5em; }
html:lang(es) .nav-quick div a span { width: 120px; }
@media only screen and (max-width: 1199px) {
	html:lang(es) .nav-quick div a .fa { font-size: 1.2em;}
	html:lang(es) .nav-quick div a span { width: 100px; }
}
@media only screen and (max-width: 400px) {
	html:lang(es) .nav-quick div a .fa { font-size: 1em; }
}

@media only screen and (min-width: 992px) {
	.node-type-event .field-name-field-mapped-location { 
	    margin-left: 180px;
	}
}
.node-type-event .field-name-field-mapped-location .field-label { display: none; }
.col-lg-12 { width: 100%; }
.group-address input, .group-contact input, .group-contact select, .group-contact .chosen-container {
    max-width: 300px;
}

@media only screen and (min-width: 768px) {
	.breadcrumb.make-abs { width: 720px; }
	.webform-component--mark-private .description { position: absolute; margin-top: -30px; right: 15px; } 
}
@media only screen and (min-width: 992px) {
	.breadcrumb.make-abs { width: 940px; }
}
@media only screen and (min-width: 1170px) {
	.breadcrumb.make-abs { width: 1140px; }
	.nav > li > a {
		padding: 10px 20px;
	}
}

@media only screen and (min-width: 992px) {
	.alert-site.fixed { position: fixed; }
	.st-nav.fixed { height: 60px; transition: all 0.3s ease-in-out; }
	.st-nav.fixed .st-nav-links { margin-top: 0px;  }
	.st-nav.fixed a.st-logo { padding: 10px 0px; }
	.st-nav.fixed a.st-logo img { height: 40px; }
	.st-nav.fixed .st-nav-links a { padding-top: 0px; }
	.st-nav.fixed .st-nav-links2 { margin-top: 0px;  }
	.st-nav.fixed .st-nav-links2 a { padding-top: 4px; padding-bottom: 4px; }
	.label-inline, .field-label-inline .field-label, .rrssb-prefix {
		font-weight: bold;
		display: inline-block;
		width: 180px;
		text-align: right;
		padding-right: 10px;
		vertical-align: top;
		margin-top: 0px;
	}
	.rrssb .rrssb-prefix {
		margin-left: -15px;
	}
	.node-type-board .label-inline, .node-type-board .field-label-inline .field-label {
		width: 240px;	
	}
/*	span.date-display-remaining {
		margin-left: 180px;
	}
*/
	.field-label-inline .field-items {
		display: inline-block;
	}
	td .field { margin-top: 0px !important; }
}
h2 a.btn.pull-right { margin-top: 16px !important; margin-bottom: -13px !important; }
	
@media only screen and (max-width: 1199px) {
	.col-md-12 { width: 100%; }
	a.st-logo { padding: 20px 20px 20px 0; width: 150px; }
	.nav-icon a { font-size: 1.1em; }
	.nav-icon a .fa { font-size: 2em; padding: 10px 0px 10px 0px; width: 60px; margin-right: 15px; vertical-align: middle; }
	.nav-icon div span { height: 56px; line-height: 1.3em; }
}
@media only screen and (max-width: 991px) {
	.st-nav { position: absolute; margin-top: 0px; }
	.banner, .carousel .container, .carousel .item { height: 335px; }
	.banner-info { margin-top: 250px; }
	.carousel-control { padding-top: 90px;  }
	.page-node-8886 .carousel-control { padding-top: 90px; padding-bottom: 80px; }
	.banner-content .banner-txt { font-size: 18px; padding-top: 150px; padding-bottom: 10px; }
	.banner-logo { padding-top: 120px; }
	.banner-logo img { max-height: 90px; }
	.banner-content .search { font-size: 17px; }
	.banner-content .search button { font-size: 15px; padding: 6px 5px 6px 12px; }
	.banner-content .search input[type='text'] { padding: 6px 7px 5px 7px; border: 1px solid #fff; height: 37px; }
	.navbar { padding: 15px; }
	.navbar-header { float: left; text-align: center; }
	.navbar-default .navbar-toggle {
		border-color: transparent;
	}
	.navbar-default button.navbar-toggle:focus, .navbar-default button.navbar-toggle:hover {
		background-color: transparent;
	}
	.navbar-default button.navbar-toggle:focus  {
		border-color: #fff;
	}
	.navbar-default .navbar-toggle .icon-bar {
		background-color: #fff;
	}
	.container > .navbar-collapse, .container > .navbar-header {
		margin-right: -15px;
		margin-left: -15px;
	}
	.st-nav {
		height: 80px;
		background: #00584c;
	}
	.navbar-nav .open .dropdown-menu .dropdown-header, .navbar-nav .open .dropdown-menu > li > a {
		padding: 5px 15px 5px 35px; text-transform: none;
	}
	.nav-container {
		height: 60px;
	}
	.nav > li > a.dropdown-toggle .caret {
		display: inline-block;
		float: right;
		border-top: 6px dashed;
		border-top: 6px solid\9;
		border-right: 6px solid transparent;
		border-left: 6px solid transparent;
	}
	.nav > .dropdown.open a.dropdown-toggle .caret {
		transform: rotate(180deg);
	}

	.dropdown-menu a { color: #fff !important; }
	.st-logo-mobile { display: block; }
	.icon-links-mobile { display: block; position: absolute; right: 20px; font-size: 24px; z-index: 3; }
	.icon-links-mobile a { display: inline-block; color: #fff; padding: 9px; border: none; text-decoration: none; border: 1px solid transparent; }
	.icon-links-mobile a:hover, .navbar-toggle:hover, .st-logo-mobile a  { background: none !important; }
	.navbar-collapse { margin: 60px -5px 0 -5px;  padding: 0px; }
	.st-logo-mobile { position: absolute; width: 100%; top: 10px; }
	.st-logo-mobile a { display: inline-block; padding: 10px; border: none;  }
	.st-logo-mobile a:hover, .navbar-toggle:hover, .icon-links-mobile a:hover { opacity: .75 !important; }
	.navbar-toggle {
		position: absolute;
		z-index: 1;
		float: none;
		padding: 0 5px;
		margin: 10px 0 0 15px;
		background-color: transparent;
		background-image: none;
		border: 1px solid transparent;
		border-radius: 0px;
	}
	.navbar-toggle .icon-bar {
		display: block;
		width: 32px;
		height: 2px;
		border-radius: 1px;
		margin: 7px 0;
	}
	.st-logo-mobile img { margin: auto 0px; height: 40px; margin-right: 40px;}
	.navbar-collapse.collapse {
		display: none !important;
	}
	.navbar-collapse.collapse.in {
		display: block !important;
	}
	.navbar-header .collapse, .navbar-toggle {
		display:block !important;
	}
	.navbar-header {
		float: none;
	}
	.nav-photo2 .inner { min-height: none; }
	.st-nav-links2 { float: none; margin: 0px; display: block; clear: before; }
	.st-nav a.outline, .my-account-icon { display: none;  }
	.my-account-label { display: inline-block; padding-left: 5px; }
	.st-nav-links2 a { display: block; background-color: #00584C; margin: 0px;   }
	.st-nav-links2 a:hover { background-color: #231f20 !important; }
	.st-logo { display: none; }
	.st-foot h3 { margin-top: 20px; }
	.st-foot { padding-top: 0px; }

	.iwant .iwant1 li.active, .iwantto .iwant .iwant1 li.active {
		padding-right: 5px;
	}
	.iwant .iwant1 li, .iwantto .iwant .iwant1 li {
		padding: 15px 5px;
	}
	body.not-front .banner, body.not-front .carousel .container, body.not-front .carousel .container .banner-content, body.not-front .carousel .item {
		height: 290px;
	}
	.node-event span.date-display-single, .node-event div.date-display-remaining {
		display: block; margin-left: 0px;
	}
	.bboard .st-nav { background: #005569; }
	
}

.month-mobile { display: none; }
@media (max-width: 959px) {
	.calendar-from-month td:before { padding: 2px; }
	.month-mobile { text-align: left; display: block; padding-top: 5px; font-weight: bold; }
}
@media only screen and (max-width: 767px) {
	.col-xs-12 { width: 100%; }
	.pull-right-margin { margin: 0 0 20px 0px; float: none !important; display: block !important; }
	body.not-front .banner-content {height:290px !important}
	.offero-img { width: 200px; }
	.col-xs-12 { width: 100%; }
	h1, h2.iwant-h1 { font-size: 32px; padding-bottom: 15px; }
	h1:before, h2.underline:before, h3.underline:before { border-bottom-width: 3px; }
	.nav-btn a { border-top: 3px solid #9f481b; font-size: 1.1em;  }
	.spotlights .article { border-top-width: 3px !important; }
	.horizontal-tabs ul.horizontal-tabs-list li a, .horizontal-tabs ul.horizontal-tabs-list li.selected a { padding: 0.25em 0.35em; }
	.nav-btn a .fa { font-size: 1.1em;  }
	/* iwant styles */
	.iwant ul { height: auto; }
	.iwant .iwant1 li { padding: 6px 5px; }
	.iwant2 { height: 30px !important; padding-left: 10px !important; }
	.node-type-bboard-page .iwant2 { min-height: 30px !important; }
	.iwant .iwant1 li, .iwantto .iwant .iwant1 li { margin-bottom: 2px !important; }	
	.iwant3 .rootlist ul { height: 235px; overflow-y: auto; padding-top: 10px; }
	.iwant1, .iwant2, .iwant3 { float: none; width: 100%; }
	.iwant .iwant1 li { text-align: left; display: inline-block; }
	.iwant .iwant1 li { padding: 6px 10px; margin-bottom: 8px; margin-right: 0px; }
	.iwant .iwant1 li.active { padding-right: 10px; }
	.node-type-bboard-page .iwant3 .rootlist {  margin-top: -35px !important; }
	.bg-icon { height: 80px; width: 80px;  margin: -20px 0 0 -20px; background-image: url(/sites/all/themes/larimer/assets/images/home-iwant.png); }
	.bg-icon0 { background-position: 0 0;  }
	.bg-icon1 { background-position: -80px 0; }
	.bg-icon2 { background-position: -160px 0; }
	.bg-icon3 { background-position: -240px 0; }
	.bg-icon, .bg-icon.active { display: none !important; }
	.st-foot .row { text-align: center; }
	.nav-photo2 .col { margin-bottom: 10px; }
	.nav-photo2 h3, .nav-photo2 a.top-half { margin-bottom: 10px; padding-bottom: 0px; }
	.nav-photo2 .inner div { padding-top: 0px; }
	/* .nav-photo2 a img { max-height: 100px; } */

	.alert-page a { min-height: 84px; }
	.alert-page a span.h3 { margin: 0 0 2px; font-size: 20px; }
	.alert-page a span.div {  margin-left: 90px; }
	.alert-page a .fa.big-icon { font-size: 2.5em; width: 70px !important; padding: 20px 0 25px 10px; }
	#google_translate_element { margin: 0px auto; padding-left: 65px !important;}
	#google_translate_element select { margin-left: -15px; }

	#searchModal .btn-lg, #searchModal .btn-group-lg > .btn { padding: 6px 8px; font-size: 15px; }
	#searchModal form .btn { width: auto; display: inline-block; margin-bottom: 0px; }
	#searchModal .modal-search { width: 350px; padding: 6px 10px; font-size: 16px; }
	.sunrise { display: block; padding-bottom: 10px; float: none; }
	.page-header.titleicon {
		padding-left: 60px !important;
		background-size: 47px !important;
		background-position: 0 3px !important;
		padding-top: 3px !important;
	}
	.view-natural-resources-calendar .col {
		min-height:130px;
	}

	a#webfeedback {
		display: none !important;
	}

	.main-content img { height: auto !important; }
	.da-ask { float: none; width: 100%; margin: 20px 0; }
	ul.nav.nav-pills li > a { padding: 5px 10px !important; }
	
} 

@media only screen and (max-width: 600px) {
	.iwant .iwant1 li, .iwant2 { font-size: .8em !important; }
	.iwant3 a, .iwant3 a:hover { font-size: 1.2em; }
	.iwant3 .rootlist { margin-left: -20px !important; }
	.banner-content { height: 290px; }
	.st-nav-links .link-label { display: none; }
	.st-nav-links a { font-size: 30px; display: inline-block; padding: 10px 0; margin: 10px 5px; }
	.st-nav-links { margin: 15px 20px 0 0;  }
	a.st-logo { width: 180px; }
	h3 .fa, h4 .fa { width: auto; }
	#searchModal .modal-search { width: 240px; }
	.offero-img { float: none; margin: 20px 0; width: 100%; }
	img.w-icon {
		margin-top: -4px;
		height: 48px;
	}
	.weather .day {
		font-size: .8em;
	}
	.lpad { margin-left: 0px !important; }
	.panel-body { padding: 5px !important; }
	.trails-app .fa { display: inline-block; width: auto; }
	.temp-deg { font-size: 1em; }
	#google_translate_element {
		width: 117px;padding-left: 20px !important;
	}
	h2 a.btn.pull-right span, h1 a.btn.pull-right span, h3 a.btn.pull-right span { display: none; }
	.view-natural-resources-calendar .views-field.views-field-field-event-date { width: 70px; font-size: 1.5em; }
	.view-natural-resources-calendar .views-field.views-field-field-event-date-1 { width: 70px; font-size: 2.4em; padding-bottom: 10px; }
	.upcoming-summary { margin: 10px 0 0 85px; }
	.view-natural-resources-calendar .col { min-height: 90px; }
	.embedded-map { width: 100%; height: 320px; border: 0px; }
}

@media only screen and (max-width: 480px) {
	.st-nav-links .link-label { display: none; }
	.st-nav-links a { font-size: 30px; display: inline-block; padding: 10px 0; margin: 10px 5px; }
	.st-nav-links { margin: 15px 20px 0 0;  }
	a.st-logo { width: 180px; }
	#searchModal .modal-search { width: 200px; }
	#searchModal .new-tab { display: none !important; }
	.map-popup-image { display: none; }
	.info-window { width: auto !important; }
	.park-thumb { width: 60px; }
	.park-desc { padding-left: 70px; }
	.park-features.col strong { font-size: 1.1em; }
	.nav-btn a { border-top: 2px solid #9f481b; }
}
@media only screen and (max-width: 360px) {
	#searchModal .modal-search { width: 180px; }
}

/* MIN HEIGHT so no white space below footer */
@media only screen and (min-height: 700px) {
	.main-content { min-height: 500px; }
}

@media only screen and (min-height: 800px) {
	.main-content { min-height: 600px; }
}

/* Board styles */
.node-board .view-mode-contact_details { margin-bottom: 5px; }
.boardlistings {
	.view-content {
		width:100%;
	}
	a{
		min-height:130px !important;
		position:relative;
		&> .acceptingapps {
			display: block;
			position: absolute;
			bottom: 0px;
			left: 0px;
			text-align: center;
			width: 100%;
			font-size: 0.7em;
			padding: .5em;
			background: #9f481b;
			color: #fff;
			&:before {
				font-family: FontAwesome;
				content: "\f14a";
			}
		}
	}
}
.jumbotron h2 {
    margin-top: 0px;
}
.jumbotron {
    padding-top: 36px;
    padding-bottom: 24px;
}
@media only screen and (max-width: 991px) {
	.boardlistings a{
		min-height:150px !important;
	}
	.photo-enlarge { display: none;  margin-top: 0px !important; }
}
@media only screen and (max-width: 767px) {
	.boardlistings a{
		min-height:100px !important;
	}
}

@media screen and (min-width: 776px) and (max-width: 991px) {
	.hidden-md {
		display: none;
	}
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
	a.si2, a:hover.si2, a:visited.si2 {
		margin-right: 8px !important;
		font-size: 1.4em !important; 
	}
	.connect-wu h3 .fa { display: none; }
}


/* IE only */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.views-exposed-form label {
		font-weight: bold;
		vertical-align: top;
		padding-top: 8px;
	}
	.weather .line:hover i.fa { display: none; }
}
/* Edge only */
@supports (-ms-accelerator:true) {
	.views-exposed-form label {
		font-weight: bold;
		vertical-align: top;
		padding-top: 8px;
	}
	.weather .line:hover { min-height: 66px; }
}

/* 2023 fixes */
div#main.enforcementblue .nav-photo2 a h3, div#main.enforcementblue .nav-photo-gray h3 { color: #fff !important; }
div#main.enforcementblue .nav-photo2 a:hover  h3, div#main.enforcementblue .nav-photo-gray a:hover h3 { text-decoration: underline !important; }
.accordion-header { clear: both; }
.fa-brands.fa-x-twitter:before, .fa-kit.fa-x-twitter:before {
    content: "";
	display: inline-block;
	font-family: Font Awesome Kit;
	text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}