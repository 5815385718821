// Styles for Nodes
// -----------------------------------------------------------------------------

.page-government-departments #content ul{
  list-style:none;
  li a{
    background:#eee;
    display:block;
    padding:6px;
    font-size:1.4em;
  }
}

.view-mode-full{
  .field-name-field-featured-thumbnail-image {
    img {
      width: 100%;
      height: auto;
      margin: 5px 0 10px 0;
    }
  }
}
.field-collection-container{
  .label-above{
    font-weight:bold;
    font-size:1.5em;
    margin:5px 0;
  }
}
.field-collection-view {
  .form-wrapper{
    padding-bottom:15px;
  }
  em.repeat-indicator{
    color:#007c7b;
  }
  span.item-title{
    font-size:1.3em;
    color:#555;
    margin-bottom:10px;
    display:block;
  }
  .field-name-field-time{
    span{
      margin-left:-16px;
      font-size:1.7em;
      background:#555;
      padding:5px;color:#eee;
    }
  }

  .field-collection-view-links{
    display:block;
    margin-top:-45px !important;
    a {
      display: block;
      background: #333;
      padding: 3px;
      margin-right: 5px;
      color: #fff;
      font-size:1.1em;
      font-weight:bold;
    }
    li.delete a{
      background: #790000;
    }
  }
}
.region {
  h4.department-documents {
    font-size:18px;
    font-weight: 500;
    line-height: 1.1;
    margin-top:10px;
  }
}
.node-teaser{
    background: #eee;
    padding:0 10px;
    margin-top:5px;
    img {
      float:left;
      margin:20px 20px 10px 0px;
    }
    h1 {
      font-size:1.4em;
    }
    .field-name-published-on {
      color:#333;
      font-style: italic;
    }
    .field-name-node-link{
      float:right;
      font-size:1.2em;
      margin:0 8px 8px 0;
    }
  }

 .node-gallery .field-collection-view.clearfix.view-mode-full {
    clear: none;
    content: "";
    float: left;
    width: 25%;
	padding-bottom: 10px;
 }
 .node-gallery .field-collection-view.clearfix.view-mode-full .field { 
	line-height: 1; 
 } 
@media(max-width:992px) {
 .node-gallery .field-collection-view.clearfix.view-mode-full {
	width: 50%;
 }
}
@media(max-width:580px) {
 .node-gallery .field-collection-view.clearfix.view-mode-full {
	width: 100%;
	float: none;
 }
}